<template>
	<div v-if="page == 'cart'" class="cart-checkout m-t-20" :class="additionalClasses">
		<a href="javascript:;" class="button button-secondary checkout-btn m-r-20" @click="backButton()">
			<i class="fa fa-chevron-left" aria-hidden="true" />{{ backButtonLabel }}
		</a>
		<button class="button checkout-btn" :disabled="stock" @click="continueButton()">
			{{ continueButtonLabel }} <i class="fa fa-chevron-right" aria-hidden="true" />
		</button>
	</div>
	<div v-else id="button-row" class="row p-tb-20">
		<div class="col-sm-12 col-xs-12 col-md-6 sm-m-t-15 pull-right">
			<div v-if="((isPayPal && paypalGuid) || !isPayPal)" class="mc-buttons sm-full-width">
				<button type="submit" class="button pull-right" :disabled="orderInFlight" tabindex="1" @click="continueButton()">
					<span>{{ continueButtonLabel }}&nbsp;<i class="fa fa-chevron-right" aria-hidden="true" /></span>
				</button>
			</div>
			<div v-if="continueButtonLabel == 'paypal' && !paypalGuid" key="paypal" class="pull-right sm-full-width md-full-width">
				<PaypalButtons paypal-button-type="paypal" :billing-form-ref="billingFormRef"></PaypalButtons>
			</div>
			<div v-else-if="continueButtonLabel == 'paylater' && !paypalGuid" key="paylater" class="pull-right sm-full-width md-full-width">
				<PaypalButtons paypal-button-type="paylater" :billing-form-ref="billingFormRef"></PaypalButtons>
			</div>
		</div>
		<div class="col-sm-12 col-xs-12 col-md-6 mc-buttons pull-left">
			<a href="javascript:;" type="submit" class="button button-secondary billing-form-button" tabindex="1" @click="backButton()">
				<span><i class="fa fa-chevron-left" aria-hidden="true" />&nbsp;Back</span>
			</a>
		</div>
	</div>
</template>

<script>
import { emitter } from '../../main';
import { useCartStore } from '../../stores/CartStore'
import { useUserStore } from '../../stores/UserStore'
import PaypalButtons from "./paypal-buttons.vue"
import checkoutHelpers from '../../mixins/checkout-global-functions.js'
const axios = require('axios')
export default {
	components: {PaypalButtons},
	mixins: [checkoutHelpers],
	props: {
		page: {
			type: String,
			default: ''
		},
		backButtonLink: {
			type: String,
			default: ''
		},
		stock: {
			type: Boolean,
			default: false
		},
		additionalClasses: {
			type: String,
			default: ''
		},
		orderInFlight: {
			type: Boolean,
			default: false,
		},
		billingFormRef: {
			type: Object,
			default: null
		}
	},
	emits: ['continue', 'back'],
	data() {
		return {
			isLoggedIn: false,
			overlayIsShowing: false,
			backButtonLabel: '',
			continueButtonLabel: '',
			needsAdditionalShipmentsStep: false,
			paypalGuid: ''
		}
	},
	computed: {
		isPayPal() {
			return ['paypal', 'paylater'].includes(this.continueButtonLabel)
		},
		needsAdditionalShipmentsStep() {
			return this.cartStore.clubItemRecipients.length > 0
		}
	},
	mounted() {
		this.cartStore = useCartStore()
		this.userStore = useUserStore()
		this.isLoggedIn = this.userStore.getIsLoggedIn

		switch(this.page) {
			case 'cart':
				this.backButtonLabel = 'Continue Shopping'
				this.continueButtonLabel = 'Secure Checkout'					
				break
			case 'shipping':
				this.backButtonLabel = 'Back'
				if(this.needsAdditionalShipmentsStep) {
					this.continueButtonLabel = 'Next'
				} else {
					this.continueButtonLabel = 'Continue To Billing'
				}
				break
			case 'additional-shipments':
				this.backButtonLabel = 'Back'
				this.continueButtonLabel = 'Continue To Billing'
				break
			case 'billing':
				this.backButtonLabel = 'Back'
				this.continueButtonLabel = 'Place Order'
				break
		}

		emitter.on('paypal-selected', (evt) => {
			this.continueButtonLabel = 'paypal'
		})

		emitter.on('paypal-installment-selected', (evt) => {
			this.continueButtonLabel = 'paylater'
		})

		emitter.on("paypal-complete", (evt) => {
			this.continueButtonLabel = "Complete Order"
			this.paypalGuid = evt.paypalGuid
			this.continueButton()
		})

		emitter.on('credit-card-selected', (evt) => {
			this.continueButtonLabel = 'Place Order'
		})
	},
	methods: {
		backButton() {
			switch(this.page) {
			case 'cart':
				window.location.href = this.backButtonLink
				break;
			case 'shipping':
				window.location.href = '/checkout/cart'
				break;
			case 'additional-shipments':
			case 'billing':
				window.location.href = '/checkout/shipping'
				break;
			}

			this.$emit('back')
		},
		async continueButton() {
			// console.log('continue btn:', this.page)
			switch(this.page) {
			case 'cart':
				const [check, _] = await Promise.all([
					this.cartStore.cartPageFinalValidation(),
					useUserStore().loadCustomer(),
				])

				if(check.status == 'valid') {
					if(useUserStore().isLoggedIn) {
						window.location.href = "/checkout/shipping"
					} else {
						window.location.href = "/checkout/login"
					}
				} else {
					if(check.reason == 'item-out-of-stock') {
						if (this.cartStore?.getOutOfStockItems?.length > 0) {
							this.cartStore.getOutOfStockItems.map((item) => {   
								emitter.emit('item-out-of-stock', {item_id: item})
							})
						}
					} else if(check.reason == 'insufficient-stock' && check.itemList.length) {
						check.itemList.map((item) => {
							emitter.emit('insufficient-stock', {
								itemId: item.item_id,
								units: item.units
							})
						})
					} else if(check.reason == 'add-on-qualify') {
						var alert_message = "Shipments in your order do not qualify for Add-On items.  Please click the OK button below  ";
						alert_message += "to return to your cart and either update them to standard items or remove them before proceeding. ";
						alert_message += "To qualify, a shipment must be valued at $59.95 or more before shipping and taxes. ";
						alert(alert_message);
					} else if(check.reason == 'auto-delivery-qualify') {
						var alert_message = "At least one shipment in your order has Auto Delivery selected but does not qualify for Auto Delivery.  ";
						alert_message += "Please ensure that any shipment with Auto Delivery has at least one qualifying item.  ";
						alert_message += "Contact customer service if you need assistance correcting this issue: 1-877-377-8325";
						alert(alert_message);
					}
				}
				break
			case 'shipping':
				// console.log('shipping click?')
				this.showLoadingOverlay()
				let _this = this
				this.cartStore.shippingPageFinalValidation()
					.then(function(response) {
						if(response.data.field_errors.passed) {
							if(_this.needsAdditionalShipmentsStep) {
								window.location.href = "/checkout/additional-shipments"
							} else {
								window.location.href = "/checkout/billing"
							}
						} else {
							emitter.emit('shipping-page-validation-issues', response.data)
						}
					})
					.finally(function() {
						_this.hideLoadingOverlay()
					})
				break;
			case 'additional-shipments':
				// console.log('addtnl shipments?')
				window.location.href = "/checkout/billing"
				break
			case 'billing':
				// console.log('billing click?')
				emitter.emit('place-order-button-clicked')
				break
			}
			this.$emit('continue')
		}
	}
};
</script>