<template>
	<div v-if="shouldShowBar">
		<div class="ad-universal-promo-bar">
			Get <b>free shipping</b> on your orders with Auto Delivery. <span
				class="open-link"
				@click="toggleInfoModal"
			>Learn More</span>
		</div>
	
		<AdInfoModal v-if="showInfoModal" @close-modal="toggleInfoModal" />
	</div>
</template>

<script>
import AdInfoModal from '../modals/ad-info-modal'

export default {
	name: 'PromoBars',

	components: {
		AdInfoModal,
	},

	data() {
		return {
			showInfoModal: false
		};
	},

	computed: {
		shouldShowBar() {
			return !window.location.pathname.includes('/checkout')
		}
	},

	methods: {
		toggleInfoModal() {
			this.showInfoModal = !this.showInfoModal
		}
	},

	mounted() {
		
	}
};
</script>
<style>
	.ad-universal-promo-bar {
		padding: 6px;
		background-color: white;
		color: black;
		text-align: center;
	}
	.open-link {
		font-weight: bold;
		text-decoration: underline;
		cursor: pointer;
	}
</style>