import { createApp } from 'vue'
import { createPinia, storeToRefs } from 'pinia'
import piniaPersist from 'pinia-plugin-persistedstate'
import { useCartStore } from './stores/CartStore'
import * as Sentry from '@sentry/vue'
import { httpClientIntegration } from '@sentry/integrations'

// Vue apps
import PromoBars from './components/header/promo-bars.vue'
import MiniCart from '/src/components/header/mini-cart.vue'
import MiniCartFlyout from '/src/components/header/mini-cart-flyout.vue'
import CartPage from '/src/components/checkout/cart/cart-page.vue'
import LoginPage from '/src/components/checkout/login/login-page.vue'
import ShippingPage from '/src/components/checkout/shipping/shipping-page.vue'
import AdditionalShipmentsPage from '/src/components/checkout/additional-shipments/additional-shipments-page.vue'
import BillingPage from '/src/components/checkout/billing/billing-page.vue'
import PdpImageGallery from '/src/components/pdp/image-gallery.vue'

// Plugins
import dayJsPlugin from './plugins/dayjs'
import mittPlugin, { createLoggedEmitter } from './plugins/mitt'
import constantsPlugin from './plugins/constants'
import utilitiesPlugin from './plugins/utilities'
import VueTheMask from 'vue-the-mask'
import sentryPlugin from './plugins/sentry'
import paypalPlugin from './plugins/paypal.js'
import { usePromoCodeStore } from './stores/PromoCodeStore'

// Global LocalStorage
const pinia = createPinia();
pinia.use(piniaPersist)

// Adds Sentry to the pinia stores
pinia.use(({ store }) => {
	store.$sentry = Sentry
})

// EVENTS
export const emitter = createLoggedEmitter()

const getProps = (mountPoint) => {
	const element = document.querySelector(mountPoint)

	if (element && element.dataset.props) {
		try {
			const parsedProps = JSON.parse(element.dataset.props)
			
			return parsedProps
		} catch {
			console.info(`Could not parse props at ${mountPoint}`)
		}
	}
}

const createVueApp = (component, mountPoint) => {
	const props = getProps(mountPoint)

	const app = createApp(component, props)

	app.use(mittPlugin)
	app.use(dayJsPlugin)
	app.use(constantsPlugin)
	app.use(utilitiesPlugin)
	app.use(VueTheMask)
	app.use(sentryPlugin)
	app.use(paypalPlugin)

	// Makes `$constants` available in pinia stores
	pinia.use(({ store }) => {
		store.$constants = app.config.globalProperties.$constants
	})

	app.use(pinia)

	app.directive('tooltip', function(el, binding){
		$(el).tooltip({
			title: binding.value,
			placement: binding.arg,
			trigger: 'hover'             
		})
	})

	app.directive('maxlength', (el, binding) => {
		const maxlength = binding.value

		const event = (name) => {
			var evt = document.createEvent('Event')
			evt.initEvent(name, true, true)
			return evt
		}

		el.setAttribute('maxlength', maxlength)

		el.oninput = function(evt) {
			if (!evt.isTrusted) return
			
			if (el.value.length > maxlength) {
				el.value = el.value.slice(0, maxlength)
			}
			
			el.dispatchEvent(event('input'))
		}
	})

	return { app, mountPoint }
}

const initSentry = (apps) => {
	Sentry.init({
		app: apps.map(({ app }) => app),
		dsn: process.env.SENTRY_DSN,
		trackComponents: true,
		environment: process.env.NODE_ENV,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
			httpClientIntegration({
				failedRequestTargets: [/\/api\/.*/],
			}),
		],

		// Performance Monitoring
		tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [/^https:\/\/kcsteaks\.inetz\.com/, /^https:\/\/www\.kansascitysteaks\.com/],

		// Session Replay
		replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,

		beforeSend: (event, hint) => {
			if (process.env.NODE_ENV !== 'production') {
				console.error('Original exception:', hint.originalException || hint.syntheticException)
			}

			const cartStore = useCartStore()

			event.extra = {
				cart: {
					cart_id: cartStore.cart?.cart_id,
					cust_id: cartStore.cart?.cust_id,
					promocode: usePromoCodeStore().code,
				},
				ab_tests: cartStore.cart?.ab_tests
			}

			return event
		}
	})
}

const mountApps = (apps) => {
	apps.forEach(({ app, mountPoint }) => {
		const mountElement = document.getElementById(mountPoint.substring(1))
		if (mountElement) {
			app.mount(mountPoint)
		}
	})
}

const apps = [
	{ component: PromoBars, mountPoint: '#vue-promo-bars' },
	{ component: MiniCart, mountPoint: '#mini-cart-app' },
	{ component: MiniCartFlyout, mountPoint: '#mini-cart-flyout-app' },
	{ component: CartPage, mountPoint: '#vue-cart-page' },
	{ component: LoginPage, mountPoint: '#vue-login-page' },
	{ component: ShippingPage, mountPoint: '#vue-shipping-app' },
	{ component: AdditionalShipmentsPage, mountPoint: '#additional-shipments-page' },
	{ component: BillingPage, mountPoint: '#billing-page' },
	{ component: PdpImageGallery, mountPoint: '#vue-pdp-imagegallery' },
].map(({ component, mountPoint }) => createVueApp(component, mountPoint))

// Initialize Sentry
initSentry(apps)

// Mount apps to DOM
mountApps(apps)

// listen for items being added to the cart outside of Vue
window.addEventListener('vueMiniCartUpdate', (event) => {
	useCartStore().updateCart(event.detail.cart)
})

window.addEventListener('vueLogin', (event) => {
	const { customer = null } = event.detail

	if (customer) {
		Sentry.setUser({
			id: customer.cust_id,
			email: customer.email,
			username: `${customer.fname} ${customer.lname}`
		})
	}
})