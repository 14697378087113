<template>
	<div>
		<div class="pp-installment-message"></div>
		<div v-if="showBeforeShipping" class="pp-before-shipping">Before shipping</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useCartStore } from '../../stores/CartStore'
import checkoutHelpers from '../../mixins/checkout-global-functions.js'
import { emitter } from '../../main';

export default {
	mixins: [checkoutHelpers],
	props: ['page'],
	data() {
		return {
			paypal: {},
			orderTotal: 0
		}
	},
	computed: {
		showBeforeShipping() {
			let testArray = ['cart','shipping']
			return testArray.includes(this.page)
		},
		...mapState(useCartStore, {
			cart: 'fullCart',
		}),
		totalBeforeShipping() {
			let total = this.cart.summary_section_totals.before_shipping_subtotal

			if (this.cart.gift_card_json) {
				Object.values(this.cart.gift_card_json).forEach((giftCard) => {
					if (giftCard.applied_amount) {
						total -= giftCard.applied_amount
					}
				})
			}

			total += 0
			return total
		}
	},
	created() {},
	mounted() {
		this.cartStore = useCartStore()

		this.loadPayLaterMessage()

		emitter.on('cart-updated', (evt) => {
			this.loadPayLaterMessage()
		})

		emitter.on('cart-updated-from-server', (evt) => {
			this.loadPayLaterMessage()
		})

		emitter.on('cart-tax-updated', (evt) => {
			this.loadPayLaterMessage()
		})

		console.log(this.page)
	},
	methods: {
		async loadPayLaterMessage() {
			if(typeof(this.page) == 'undefined' || this.page != 'billing') {
				this.orderTotal = this.totalBeforeShipping
			} else {
				this.orderTotal = this.cartStore.getCartTotal
			}

			await this.$loadPaypal()
			.then((paypal) => {
				if(paypal) {
					paypal.Messages({
						amount: this.orderTotal,
						pageType: 'checkout',
						style: {
							layout: 'text',
							text: {
								size: 16
							},
							logo: {
								type: 'alternative',
								position: 'left'
							}
						}
					}).render('.pp-installment-message');
				}
			});
		}
	}
};
</script>

<style>
	.pp-installment-message {
		
	}
	.pp-before-shipping {
		margin-bottom: 10px;
		margin-top: -10px;
		text-align: center;
	}
</style>