<template>
	<div class="sg-form">
		<div v-if="showStaticBillingInfoWithEdit">
			<!-- Start Static Address Block With Edit Button -->
			<div class="flexbox">
				<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div class="col-md-8 col-md-offset-4 col-sm-12 col-xs-12">
						<a class="acc-edit m-l-0" href="javascript:;" @click="editBillingAddress()">
							<i class="fa fa-pencil-square-o" aria-hidden="true" /> Edit
						</a>
						<p class="acc-p">
							{{ billingAddress.fname }} {{ billingAddress.lname }}<br>
							<span v-if="billingAddress.company">{{ billingAddress.company }}<br></span>
							{{ billingAddress.address_1 }}<br>
							<span v-if="billingAddress.address_2">{{ billingAddress.address_2 }}<br></span>
							<span v-if="billingAddress.city && billingAddress.state">
								{{ billingAddress.city }}, {{ billingAddress.state }} {{ billingAddress.postal_code }}
							</span><br>
							{{ billingAddress.email }}<br>
							{{ billingAddress.phone }}<br>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div v-else>
			<div v-if="hasOnlyOneShippingAddress && !savedCardIsSelected" class="flexbox">
				<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div class="col-md-9 col-md-offset-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
						<div class="same-as-shipping input_checkbox_group">
							<label>
								<div class="input_wrapper m-l-0">
									<input
										id="same-as-shipping"
										v-model="sameAsShipping"
										type="checkbox"
										:checked="sameAsShipping"
										@change="toggleSameAsShipping()"
									>
									<div class="input_checkbox m-r-5" />
									<div>BILLING ADDRESS SAME AS SHIPPING</div>
								</div>
							</label>
						</div>
					</div>
				</div>
			</div>

			<div v-if="!showStaticBillingInfoWithEdit">
				<div v-if="showBillingAddressSelect" class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label
								class="align-self-c"
								style="margin-top: 0 !important;"
								for="choose-address"
							><span class="required-asterisk color-primary">*</span>CHOOSE A SAVED ADDRESS</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<div class="arrow bg-color-form-well">
								<select id="saved-address" v-model="chosenSavedAddrId" class="form-control" tabindex="1" @change="chooseSavedAddressForBilling">
									<option value="new">New Address</option>
									<option v-for="address in billingAddressOptions" :key="address.addr_id" :value="address.addr_id">{{ address.selector_label }}</option>
								</select>
							</div>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': billingValidationErrors.fname.error}">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'fname-'+billingAddress.crec_id">
								<span class="required-asterisk color-primary">*</span>
								FIRST NAME
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'fname-'+billingAddress.crec_id" v-model="billingAddress.fname" v-maxlength="15" required type="text" class="form-control" autocomplete="billing given-name" :disabled="inputDisabled">
							<div v-if="billingValidationErrors.fname.error" class="p-lr-0" style="">
								<span class="color-primary inline-block ng-binding">{{ billingValidationErrors.fname.message }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': billingValidationErrors.lname.error}">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'lname-'+billingAddress.crec_id">
								<span class="required-asterisk color-primary">*</span>
								LAST NAME
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'lname-'+billingAddress.crec_id" v-model="billingAddress.lname" v-maxlength="15" required type="text" class="form-control" autocomplete="billing family-name" :disabled="inputDisabled">
							<div v-if="billingValidationErrors.lname.error" class="p-lr-0" style="">
								<span class="color-primary inline-block ng-binding">{{ billingValidationErrors.lname.message }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': billingValidationErrors.company.error}">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'company-'+billingAddress.crec_id">
								COMPANY NAME
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'company-'+billingAddress.crec_id" v-model="billingAddress.company" v-maxlength="30" type="text" class="form-control" autocomplete="off" :disabled="inputDisabled">
							<div v-if="billingValidationErrors.company.error" class="p-lr-0" style="">
								<span class="color-primary inline-block ng-binding">{{ billingValidationErrors.company.message }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': billingValidationErrors.address_1.error}">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'address1-'+billingAddress.crec_id">
								<span class="required-asterisk color-primary">*</span>
								ADDRESS 1
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'address1-'+billingAddress.crec_id" v-model="billingAddress.address_1" v-maxlength="30" type="text" class="form-control" autocomplete="billing address-line1" :disabled="inputDisabled">
							<div v-if="billingValidationErrors.address_1.error" class="p-lr-0" style="">
								<span class="color-primary inline-block ng-binding">{{ billingValidationErrors.address_1.message }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': billingValidationErrors.address_2.error}">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'address2-'+billingAddress.crec_id">
								ADDRESS 2
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'address2-'+billingAddress.crec_id" v-model="billingAddress.address_2" v-maxlength="30" type="text" class="form-control" autocomplete="billing address-line2" :disabled="inputDisabled">
							<div v-if="billingValidationErrors.address_2.error" class="p-lr-0" style="">
								<span class="color-primary inline-block ng-binding">{{ billingValidationErrors.address_2.message }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': billingValidationErrors.city.error }">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'city-'+billingAddress.crec_id">
								<span class="required-asterisk color-primary">*</span>
								CITY
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'city-'+billingAddress.crec_id" v-model="billingAddress.city" v-maxlength="19" required type="text" class="form-control" autocomplete="billing address-level2" :disabled="inputDisabled">
							<div v-if="billingValidationErrors.city.error" class="p-lr-0" style="">
								<span class="color-primary inline-block ng-binding">{{ billingValidationErrors.city.message }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{ 'has-error': billingValidationErrors.state.error }">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" for="'billing-state"><span class="required-asterisk color-primary">*</span> State</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<div class="arrow bg-color-form-well" :class="{ 'has-error': billingValidationErrors.state.error }">
								<select
									id="billing-state"
									v-model="billingAddress.state"
									class="form-control"
									:disabled="showingAddressCorrectionModal"
									autocomplete="billing address-level1"
								>
									<option value="">-- SELECT --</option>
									<option v-for="state in states" :value="state.id">{{ state.label }}</option>
								</select>
							</div>
							<div v-if="billingValidationErrors.state.error" class="p-lr-0" style="">
								<span class="color-primary inline-block ng-binding">{{ billingValidationErrors.state.message }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': billingValidationErrors.postal_code.error }">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'zip-'+billingAddress.crec_id">
								<span class="required-asterisk color-primary">*</span>
								ZIP CODE
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'zip-'+billingAddress.crec_id" v-model="billingAddress.postal_code" v-mask="'#####-####'" v-maxlength="10" required type="text" class="form-control" autocomplete="billing postal-code" :disabled="inputDisabled">
							<div v-if="billingValidationErrors.postal_code.error" class="p-lr-0" style="">
								<span class="color-primary inline-block ng-binding">{{ billingValidationErrors.postal_code.message }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': billingValidationErrors.email.error }">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'email-'+billingAddress.crec_id">
								<span class="required-asterisk color-primary">*</span>
								EMAIL ADDRESS
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'email-'+billingAddress.crec_id" v-model="billingAddress.email" v-maxlength="50" required type="text" class="form-control" autocomplete="billing email" :disabled="inputDisabled">
							<div v-if="billingValidationErrors.email.error" class="color-primary">
								{{ billingValidationErrors.email.message }}
							</div>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': billingValidationErrors.phone.error }">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'phone-'+billingAddress.crec_id">
								<span class="required-asterisk color-primary">*</span>
								PHONE
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'phone-'+billingAddress.crec_id" v-model="billingAddress.phone" v-mask="'(###) ###-####'" v-maxlength="14" required type="text" class="form-control" autocomplete="billing tel-national" :disabled="inputDisabled">
							<div v-if="billingValidationErrors.phone.error" class="color-primary">
								{{ billingValidationErrors.phone.message }}
							</div>
						</div>
					</div>
				</div>

				<div v-if="showSMSOptIn" class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="col-md-9 col-md-offset-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<div class="same-as-shipping input_checkbox_group">
								<label>
									<div class="input_wrapper m-l-0" style="width: 100%">
										<input v-model="smsOptInFlag" type="checkbox">
										<div class="input_checkbox m-r-5" />
										<div>PLEASE SEND TEXT UPDATES ABOUT MY ORDERS</div>
									</div>
								</label>
							</div>
							<div v-if="smsOptInFlag" style="padding-left: 33px; font-size: 14px">
								This consent is not for marketing text messages and is not a condition of purchase. It is for texts about your orders and their shipment statuses. Message and data rates may apply and message frequency may vary. See our <a href="/terms-of-use" target="_blank" style="text-decoration: underline;">Terms and Conditions</a> and <a href="/privacy-policy" target="_blank" style="text-decoration: underline;">Privacy Policy</a>.
							</div>
						</div>
					</div>
				</div>
				<br>
			</div>
		</div>
		<div v-if="showQASModal" class="row qas-results">
			<div class="col-xs-12">
				<QASPrompts
					:recip="billingAddress"
					:qas-data="qasData"
					@complete="handleQasComplete"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { storeToRefs } from 'pinia'
import { useCartStore } from '../../../stores/CartStore'
import { useUserStore } from '../../../stores/UserStore'
import checkoutHelpers from '../../../mixins/checkout-global-functions.js'
import { emitter } from '../../../main.js'
const axios = require('axios')
import QASPrompts from '../../global/qas-validation-prompts.vue'
import { watch } from 'vue'

const defaultValidationErrors = () => ({
	fname: {
		error: false,
		message: '',
	},
	lname: {
		error: false,
		message: '',
	},
	company: {
		error: false,
		message: '',
	},
	address_1: {
		error: false,
		message: '',
	},
	address_2: {
		error: false,
		message: '',
	},
	city: {
		error: false,
		message: '',
	},
	state: {
		error: false,
		message: '',
	},
	postal_code: {
		error: false,
		message: '',
	},
	email: {
		error: false,
		message: ''
	},
	phone: {
		error: false,
		message: ''
	},
})

export default {
	components: {QASPrompts},
	mixins: [checkoutHelpers],
	props: {
		optedInPhoneList: {
			type: Array,
			default: () => []
		},
		savedCardIsSelectedProp: {
			type: Boolean,
			default: false
		},
		savedCardBillingAddress: {
			type: Object,
			default: undefined,
		},
	},

	emits: ['qas-fingerprint'],

	data() {
		return {
			cart: {},
			billingAddress: {
				fname: '',
				lname: '',
				company: '',
				address_1: '',
				address_2: '',
				city: '',
				state: '',
				postal_code: '',
				email: '',
				phone: '',
			},
			stashedBillingAddress: {
				fname: '',
				lname: '',
				company: '',
				address_1: '',
				address_2: '',
				city: '',
				state: '',
				postal_code: '',
				email: '',
				phone: '',
			},
			sameAsShipping: false,
			shippingAddress: {},
			showStaticBillingInfoWithEdit: false,
			savedCardIsSelected: false,
			hasOnlyOneShippingAddress: false,
			billingFingerprint: '',
			chosenSavedAddrId: '',
			stashedChosenSavedAddrId: null,
			states: [],
			inputDisabled: false,
			showingAddressCorrectionModal: false,
			billingValidationErrors: defaultValidationErrors(),
			showQASModal: false,
			qasData: null,
			QASComplete: false,
			processing: false,
			showSMSOptIn: false,
			smsOptInFlag: false
		};
	},

	computed: {
		...mapState(useCartStore, ['getAddressesById', 'recipCount', 'getSavedAddresses']),

		billingAddressOptions() {
			return this.getSavedAddresses?.map((address) => {
				if (parseInt(address.is_myself) === 1) {
					return {
						...address,
						selector_label: 'Myself'
					}
				}

				return address
			})
		},

		isMyselfOnlyRecipient() {
			return this.cart.recipients.every((recipient) => parseInt(recipient.is_myself) === 1)
		},

		showBillingAddressSelect() {
			if(!useUserStore().getIsLoggedIn && this.hasOnlyOneShippingAddress) {
				return false
			} else if(!useUserStore().getIsLoggedIn && !this.hasOnlyOneShippingAddress) {
				return true
			} else if(useUserStore().getIsLoggedIn && this.getSavedAddresses?.length) {
				return true
			}

			return false
		}
	},

	created() {
		// Fetch the states list
		axios.get('/api/geo-states')
			.then(response => {
				// handle success
				// process the states data from the server
				Object.entries(response.data.state_values).forEach(entry => {
					this.states.push({id: entry[1].id, label: entry[1].label});
				});
			})
			.catch(error => {
				// handle error
				console.log(error);
			})
	},

	mounted() {
		const userStore = useUserStore()
		this.cartStore = useCartStore()
		this.cart = storeToRefs(this.cartStore).fullCart
		this.smsOptInFlag = storeToRefs(this.cartStore).smsOptInFlag
		this.QASComplete = storeToRefs(this.cartStore).QASComplete

		if (!this.savedCardIsSelectedProp) {
			this.chosenSavedAddrId = 'new'

			if (!userStore.getIsLoggedIn && userStore.guestEmail && !this.billingAddress.email && this.isMyselfOnlyRecipient) {
				this.billingAddress.email = userStore.guestEmail
			}
		}

		watch(
			() => this.chosenSavedAddrId,
			() => this.clearbillingValidationErrors()
		)

		watch(
			() => this.getAddressesById[this.chosenSavedAddrId],
			(value) => {
				if (this.sameAsShipping || (this.recipCount === 1 && !this.savedCardIsSelectedProp)) {
					this.billingAddress = {
						...this.billingAddress,
						...value,
					}
				}
			},
			{
				deep: true
			}
		)

		watch(
			() => this.savedCardBillingAddress,
			(value) => {
				if (this.savedCardIsSelected) {
					this.billingAddress = Object.assign({}, this.billingAddress, value)
				}
			},
		)

		// SMS OPT-IN
		this.checkSMSOptIn()

		// set some flags
		if(typeof(this.savedCardIsSelectedProp) != 'undefined' && this.savedCardIsSelectedProp) {
			this.savedCardIsSelected = true
		} else {
			this.savedCardIsSelected = false
		}

		emitter.on('saved-payment-selected', (evt) => {
			this.billingAddress = evt.billingAddress
			this.stashedBillingAddress = evt.billingAddress
			this.showStaticBillingInfoWithEdit = true
			this.sameAsShipping = false
			this.savedCardIsSelected = true
			this.checkSMSOptIn()
		})

		emitter.on('new-payment-selected', (evt) => {
			this.chosenSavedAddrId = 'new'
			this.stashedChosenSavedAddrId = null
			this.billingAddress = {
				fname: '',
				lname: '',
				address_1: '',
				city: '',
				state: '',
				postal_code: '',
				email: '',
				phone: '',
			}
			this.showStaticBillingInfoWithEdit = false
			this.savedCardIsSelected = false
			this.checkSMSOptIn()
		})

		emitter.on('credit-card-selected', (evt) => {
			this.chosenSavedAddrId = this.stashedChosenSavedAddrId
			this.billingAddress = this.stashedBillingAddress
		})

		emitter.on('paypal-return-with-guid', (evt) => {
			// Reset the billing address to what it was before going to PayPal
			this.billingAddress = this.cartStore.getPaypalStashedBillingAddress
		})

		emitter.on('payment-info-validated', (evt) => {
			if(this.preValidateBillingAddress()) {
				emitter.emit('place-order-billing-address-prevalidated', {'billingAddress': this.billingAddress})
			} else {
				setTimeout(() => {
					if($('.has-error:visible:first').length && $('.has-error:visible:first').offset()) {
						$('html, body').animate({
							scrollTop: $('.has-error:visible:first').offset().top - 75
						}, 500)
					}
				}, 500);
			}
		})

		emitter.on('paypal-place-order-button-clicked' , (evt) => {
			if(this.preValidateBillingAddress()) {
				this.showLoadingOverlay()
				// Stash the billing address for when we come back from PayPal
				this.cartStore.paypalStashedBillingAddress = this.billingAddress
				window.location.href = '/api/paypal/checkout/'
			}
		})

		emitter.on('billing-qas-prompt', (evt) => {
			this.qasData = evt.qasData
			this.showQASModal = true
		})

		emitter.on('billing-info-auto-fixed', (evt) => {
			this.billingAddress.address_1	= evt.autoFixAddress.address_1;
			this.billingAddress.address_2	= evt.autoFixAddress.address_2;
			this.billingAddress.city		= evt.autoFixAddress.city;
			this.billingAddress.postal_code	= evt.autoFixAddress.postal_code;
			this.billingAddress.state		= evt.autoFixAddress.state;
		})

		let recipCount = 0;
		if(!this.savedCardIsSelected) {
			for(var i = 0; i < this.cart.recipients.length; i++) {
				if ( ! this.cart.recipients[i].addr_id || this.cart.recipients[i].items.length == 0 ) { return; /* continue; */ }

				recipCount++;
			}

			if(recipCount == 1 && !this.savedCardIsSelected) {
				this.hasOnlyOneShippingAddress = true;

				this.shippingAddress.fname 			= this.cart.recipients[0].fname
				this.shippingAddress.lname 			= this.cart.recipients[0].lname
				this.shippingAddress.address_1 		= this.cart.recipients[0].address_1
				this.shippingAddress.city 			= this.cart.recipients[0].city
				this.shippingAddress.state 			= this.cart.recipients[0].state
				this.shippingAddress.postal_code 	= this.cart.recipients[0].postal_code
				this.shippingAddress.email 			= this.cart.recipients[0].email
				this.shippingAddress.phone 			= this.cart.recipients[0].phone
			}
		}

		this.sameAsShipping = storeToRefs(this.cartStore).billingSameAsShipping
		if(this.sameAsShipping) {
			this.toggleSameAsShipping()
			this.preValidateBillingAddress()
		}
	},

	methods: {
		chooseSavedAddressForBilling() {
			if (this.chosenSavedAddrId === 'new') {
				this.billingAddress.fname		= ''
				this.billingAddress.lname		= ''
				this.billingAddress.address_1	= ''
				this.billingAddress.address_2	= ''
				this.billingAddress.city		= ''
				this.billingAddress.state		= ''
				this.billingAddress.postal_code = ''
				this.billingAddress.email		= ''
				this.billingAddress.phone		= ''
			} else {
				const selectedAddress = this.getAddressesById[this.chosenSavedAddrId]

				if (selectedAddress) {
					this.billingAddress.fname		= selectedAddress.fname
					this.billingAddress.lname		= selectedAddress.lname
					this.billingAddress.address_1	= selectedAddress.address_1
					this.billingAddress.address_2	= selectedAddress.address_2
					this.billingAddress.city		= selectedAddress.city
					this.billingAddress.state		= selectedAddress.state
					this.billingAddress.postal_code = selectedAddress.postal_code
					this.billingAddress.email		= selectedAddress.email
					this.billingAddress.phone		= selectedAddress.phone
				}
			}

			this.checkSMSOptIn()
		},
		toggleSameAsShipping() {

			if(this.sameAsShipping) {
				this.billingAddress.fname = this.shippingAddress.fname
				this.billingAddress.lname = this.shippingAddress.lname
				this.billingAddress.address_1 = this.shippingAddress.address_1
				this.billingAddress.address_2 = this.shippingAddress.address_2
				this.billingAddress.city = this.shippingAddress.city
				this.billingAddress.state = this.shippingAddress.state
				this.billingAddress.postal_code = this.shippingAddress.postal_code
				this.billingAddress.email = this.shippingAddress.email
				this.billingAddress.phone = this.shippingAddress.phone

				this.showStaticBillingInfoWithEdit = true;

				this.preValidateBillingAddress()
			} else {
				this.billingAddress.fname = ''
				this.billingAddress.lname = ''
				this.billingAddress.address_1 = ''
				this.billingAddress.address_2 = ''
				this.billingAddress.city = ''
				this.billingAddress.state = ''
				this.billingAddress.postal_code = ''
				this.billingAddress.email = ''
				this.billingAddress.phone = ''
			}
		},
		preValidateBillingAddress(options = {}) {
			let testResults = this.simpleAddressVaildate(this.billingAddress, 'billing')

			if(!testResults.formIsValid) {
				this.hideLoadingOverlay()
				this.showStaticBillingInfoWithEdit = false
				if(typeof(options.showErrors) === 'undefined' || options.showErrors === true) {
					this.billingValidationErrors = testResults.validationErrors
					this.scrollToError()
				}
			} else {
				this.clearbillingValidationErrors()
			}

			return testResults.formIsValid
		},
		scrollToError() {
			setTimeout(() => { 
				if($('.has-error:visible:first').length && $('.has-error:visible:first').offset()) {
					console.log("SCROLLING")
					$('html, body').animate({
						scrollTop: $('.has-error:visible:first').offset().top - 75
					}, 500)
				}
			}, 500)
		},
		clearbillingValidationErrors() {
			this.billingValidationErrors = defaultValidationErrors()
		},
		validationChoiceUseSuggested() {
			this.billingAddress.address_1	= this.qasData.suggested_address.address_1
			this.billingAddress.address_2	= this.qasData.suggested_address.address_2
			this.billingAddress.city		= this.qasData.suggested_address.city
			this.billingAddress.postal_code	= this.qasData.suggested_address.postal_code
			this.billingAddress.state		= this.qasData.suggested_address.state
			this.billingAddress.res_com		= this.qasData.address_type

			if(this.qasData.suggested_address.company != 'undefined') {
				this.billingAddress.company = this.qasData.suggested_address.company;
			}

			this.saveValidationChoice('suggested')
		},
		validationChoiceUsePicklistSelection(picklistKey) {
			let selectedAddress = this.qasData.picklist[picklistKey].address_parts

			this.billingAddress.address_1	= selectedAddress.address_1;
			this.billingAddress.address_2	= selectedAddress.address_2;
			this.billingAddress.city		= selectedAddress.city;
			this.billingAddress.postal_code	= selectedAddress.postal_code;
			this.billingAddress.state		= selectedAddress.state;
			this.billingAddress.res_com		= this.qasData.addr_type

			if(selectedAddress.company != 'undefined') {
				this.billingAddress.company = selectedAddress.company;
			}
			this.saveValidationChoice('selected')
		},
		validationChoiceUseMyAddress() {
			this.billingAddress.res_com = 'U'
			this.saveValidationChoice('mine')
		},
		saveValidationChoice(userChoiceType) {
			this.QASComplete = true
			// Notify the server of Customer's validation choice
			axios.post('/api/checkout/record-billing-qas-validation-selection', {
				user_choice_type: userChoiceType,
				aval_id: this.qasData.aval_id,
				selected_address: this.billingAddress
			})
				.then(response => {
					// handle success
					this.$emit('qas-fingerprint', this.$utilities.generateAddressFingerprint(this.billingAddress))
				})
				.catch(error => {
					this.$sentry.captureException(error)
				})
				.finally(() => {
					this.processing = false
				})
		},
		editBillingAddress() {
			this.showStaticBillingInfoWithEdit = false
			this.sameAsShipping = false
			this.checkSMSOptIn()
		},
		checkSMSOptIn() {
			if(this.billingAddress.phone) {
				if(this.optedInPhoneList.includes(this.billingAddress.phone.replace(/\D/g,''))) {
					this.showSMSOptIn = false;
					this.smsOptInFlag = true
				} else {
					this.showSMSOptIn = true;
					this.smsOptInFlag = false;
				}
			} else {
				this.showSMSOptIn = true;
				this.smsOptInFlag = false;
			}
		},
		handleQasComplete(evt) {
			switch(evt.action) {
			case 'edit':
				this.showQASModal = false
				this.processing = false
				this.showStaticBillingInfoWithEdit = false
				break
			case 'save-selected':
				// console.log("edit form switch")
				this.validationChoiceUsePicklistSelection(evt.picklistKey)
				break
			case 'save-suggested':
				this.validationChoiceUseSuggested()
				break
			case 'use-my-address':
				this.validationChoiceUseMyAddress()
				break
			default:
				this.showQASModal = false
				this.processing = false
			}

			this.showQASModal = false
		},
		handleValidationErrors(errors) {
			errors.forEach((error) => {
				Object.entries(error).forEach(([key, [_error]]) => {
					if (this.billingValidationErrors[key]) {
						this.billingValidationErrors[key].error = true
						this.billingValidationErrors[key].message = _error[0]
					}
				})
			})
		},
	}
}
</script>