<template>
	<div v-if="summaryCart" class="order-sum">
		<div v-if="showOrderSummary" class="pdp-name yellow_border_bottom">
			<h4>Order Summary</h4>
		</div>
		<div v-if="hasItemPromo">
			<div class="col-md-12 promo-code-text">
				<span>Promo Code: <span class="bold">{{ itemPromo.code }}</span></span>
			</div>
			<div class="col-md-12 promo-code-description">
				<span v-if="itemPromo.name">{{ itemPromo.name }}</span>
			</div>
		</div>
		<div v-if="summaryCart.summary_section_totals" :class="{'sub-total-container' : showOrderSummary, 'm-t-15 m-b-15' : !showOrderSummary}">
			<div class="font-sm row">
				<!-- ORDER TOTAL VALUE -->
				<div class="col-xs-12">
					<p class="pull-left">Total Order Value</p>
					<p class="pull-right">
						{{ currency(summaryCart.summary_section_totals.strike_subtotal) }}
					</p>
				</div>

				<!-- ITEM DISCOUNT -->
				<div v-if="summaryCart.summary_section_totals.line_savings > 0.005 || summaryCart.summary_section_totals.display_line_savings > 0.005" class="col-xs-12">
					<p class="pull-left">Item Discount</p>
					<p v-if="page === 'cart'" class="pull-right cart-discount-amount">
						-{{ currency(summaryCart.summary_section_totals.line_savings) }}
					</p>
					<p v-else class="pull-right cart-discount-amount">
						<span v-if="summaryCart.summary_section_totals.display_line_savings">
							-{{ currency(summaryCart.summary_section_totals.display_line_savings) }}
						</span>
						<span v-else> calculating <i class="fa fa-spinner fa-spin fa-fw" aria-hidden="true" /></span>
					</p>
				</div>

				<!-- STANDARD SHIPPING -->
				<div v-if="page == 'billing'">
					<div class="col-xs-12">
						<p class="pull-left">Standard Shipping</p>
						<p class="pull-right">
							{{ currency(summaryCart.shipping_breakdown['Standard Shipping']) }}
						</p>
					</div>
				</div>
				
				<!-- SHIPPING UPGRADE -->
				<div v-if="page == 'billing' && showShippingUpgrade">
					<div class="col-xs-12">
						<p class="pull-left">Shipping Upgrade</p>
						<p class="pull-right">
							{{ currency(summaryCart.shipping_breakdown['Shipping Upgrade']) }}
						</p>
					</div>
				</div>

				<!-- SHIPPING DISCOUNT ( before billing page ) -->
				<div v-if="page != 'billing' && summaryCart.recalc_detail.has_potential_for_shipping_discount">
					<p class="pull-left p-l-15">
						<span class="pull-left">Shipping Discount </span>
						<br>
						<span>(Calculated in checkout)</span>
					</p>
				</div>

				<!-- SHIPPING DISCOUNT ( billing page )-->
				<div v-if="page == 'billing' && summaryCart.summary_section_totals.promo_discounts.shipping_discount > 0.005" class="col-xs-12">
					<p class="pull-left">Shipping Discount<br></p>
					<p v-if="summaryCart.summary_section_totals.promo_discounts.shipping_discount > 0.005" class="pull-right cart-discount-amount">
						-{{ currency(summaryCart.summary_section_totals.promo_discounts.shipping_discount) }}
					</p>
				</div>

				<!-- TAX -->
				<div v-if="page == 'billing' && summaryCart.tax > 0" class="col-xs-12">
					<p class="pull-left">Tax</p>
					<p class="pull-right">
						{{ currency(summaryCart.tax) }}
					</p>
				</div>

				<!-- GIFT CARD LOADING -->
				<div v-if="gcLoading" class="col-xs-12">
					<p class="pull-left">Gift Card Calculating</p>
					<p class="pull-right">
						<i class="fa fa-spinner fa-spin fa-fw" aria-hidden="true" />
					</p>
				</div>

				<!-- GIFT CARD ( billing page ) -->
				<div v-for="row in summaryCart.gift_card_json" :key="row.last4" class="col-xs-12">
					<p class="pull-left">
						Gift Card (*{{ row.last4 }}) <i v-if="!showPaymentType" class="fa fa-times-circle" style="color: #bbb2a4; cursor: pointer;" aria-hidden="true" @click="deleteGC(row);" />
					</p>
					<p class="pull-right">
						<span :class="row.applied_amount == 0.00 ? 'color-black' : 'cart-discount-amount'">-{{ currency(row.applied_amount) }}</span>
					</p>
				</div>

				<!-- TOTAL ( billing page only) -->
				<div v-if="page == 'billing'" class="col-xs-12 bold">
					<p class="pull-left ">Total</p>
					<p v-if="!taxCalculating" class="pull-right">{{ currency(summaryCart.summary_section_totals.cart_net_total) }}</p>
					<p v-else>calculating <i class="fa fa-spinner fa-spin fa-fw" aria-hidden="true" /></p>
				</div>

				<!-- TOTAL ( before billing page) -->
				<div v-if="page != 'billing'" class="col-xs-12 bold">
					<p class="pull-left ">Total before shipping</p>
					<p class="pull-right">{{ totalBeforeShipping }}</p>
				</div>

				<div v-if="showPaymentType">
					<br>
					<div v-if="( summaryCart.giftCardTotal < summaryCart.cart_total ) && paymentInfo.length && (paymentInfo[0].type == 'credit_card' || paymentInfo[0].type == 'paypal' )" class="col-xs-12">
						<p class="pull-left">Your Payment Type</p>
						<p v-if="paymentInfo[0].type == 'credit_card'" class="pull-right">Credit Card</p>
						<p v-if="paymentInfo[0].type == 'paypal'" class="pull-right">Paypal</p>
					</div>
					<div v-if="( summaryCart.giftCardTotal >= summaryCart.cart_total )" class="col-xs-12">
						<p class="pull-left">Your Payment Type</p>
						<p class="pull-right">Gift Card</p>
					</div>
				</div>
				<div style="clear:both" />  
				<div
					v-if="( summaryCart.summary_section_totals.saved_over_total - summaryCart.summary_section_totals.promo_discounts.shipping_discount) > 0.005 && page != 'billing'"
					class="text-center cart-savings bold font-alt2"
				>
					You've saved
					<span v-if="summaryCart.recalc_detail.has_potential_for_shipping_discount ">over</span>
					{{ currency(summaryCart.summary_section_totals.saved_over_total - summaryCart.summary_section_totals.promo_discounts.shipping_discount) }}
				</div>
				<div v-if="summaryCart.summary_section_totals.saved_over_total > 0.005 && page == 'billing'" class="text-center cart-savings bold">
					You've saved {{ currency(summaryCart.summary_section_totals.saved_over_total) }}
				</div>

				<AutoDelivery element="cart-summary" :recipient-id="cart.recipients[0].crec_id" style="text-align: left; font-size: initial;" />

				<PaypalMessages v-if="isPayPalTestB && !cartStore.isAutoDelivery" :page="page" style="padding: 15px 15px 0 15px;"></PaypalMessages>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useCartStore } from '../../stores/CartStore'
import currencyFormatterMixin from '../../mixins/currency-formatter-mixin'
import checkoutHelpers from '../../mixins/checkout-global-functions.js'
import AutoDelivery from '../global/auto-delivery.vue'
import { emitter } from '../../main';
import { usePromoCodeStore } from '../../stores/PromoCodeStore'
import PaypalMessages from './paypal-messages.vue'

const axios = require('axios')

export default {
	components: {AutoDelivery, PaypalMessages},
	mixins: [currencyFormatterMixin, checkoutHelpers],
	props: ['page'],
	data() {
		return {
			showShippingUpgrade: false,
			showOrderSummary: true,
			showPaymentType: false,
			paymentInfo: [],
			showShipping: '',
			cartStore: {},
			gcLoading: false,
			taxCalculating: false
		}
	},
	
	computed: {
		...mapState(useCartStore, {
			summaryCart: 'fullCart',
			cart: 'fullCart',
		}),
		...mapState(usePromoCodeStore, {
			hasItemPromo: 'getCode',
			itemPromo: 'getAppliedCode'
		}),
		totalBeforeShipping() {
			let total = this.summaryCart.summary_section_totals.before_shipping_subtotal

			if (this.summaryCart.gift_card_json) {
				Object.values(this.summaryCart.gift_card_json).forEach((giftCard) => {
					if (giftCard.applied_amount) {
						total -= giftCard.applied_amount
					}
				})
			}

			total += 0
			return this.currency(total)
		},
		isPayPalTestB() {
			if(this.cart.ab_tests.paypal_installments && this.cart.ab_tests.paypal_installments == 'B') {
				return true
			}
			return false
		}
	},
	created(){},
	mounted() {
		this.cartStore = useCartStore()

		emitter.on('gc-code-success', evt => {
			this.gcLoading = true
			emitter.emit('gift-card-successfully-applied')
		})
		emitter.on('gift-card-successfully-applied', (evt) => {
			useCartStore().updateCartDataFromServer().then(() => {
				this.gcLoading = false
			})
		})
			
		setTimeout(() => {
			if(
				(
					typeof(this.summaryCart.summary_section_totals) != 'undefined'
					&& typeof(this.summaryCart.summary_section_totals.promo_discounts) != 'undefined'
					&& this.summaryCart.summary_section_totals.promo_discounts
					&& typeof(this.summaryCart.summary_section_totals.promo_discounts.promo_discounts) != 'undefined'
					&& this.summaryCart.summary_section_totals.promo_discounts.promo_discounts > 0
				)
				||
				(
					typeof (this.summaryCart.shipping_discount) !== 'undefined'
					&& this.summaryCart.shipping_discount > 0
				)
			){
				if(typeof(this.summaryCart.summary_section_totals.line_savings) == 'undefined') {
					this.summaryCart.summary_section_totals.line_savings = 0;
				}
				this.summaryCart.summary_section_totals.display_line_savings = parseFloat(this.summaryCart.summary_section_totals.line_savings);
			}
		}, 900);

		emitter.on('cart-updated', (evt) => {
			this.prepTotals()
		})

		emitter.on('cart-tax-caclculating', (evt) => {
			this.taxCalculating = true
		})

		emitter.on('cart-tax-updated', (evt) => {
			this.prepTotals()
			this.taxCalculating = false
		})

		emitter.on('cart-updated-from-server', (evt) => {
			this.prepTotals()
			this.gcLoading = false
		})

		emitter.on('gift-card-removed', (evt) => {
			this.hideLoadingOverlay()
		})
		emitter.on('gc-remove-failed', (evt) => {
			this.hideLoadingOverlay()
		})
	},
	methods: {
		deleteGC(row) {
			this.showLoadingOverlay();
			// this needs to be removed from the cartStore and have the server stuff done in the backend
			// as this is taking too long when there are lots of items in the cart
			this.cartStore.removeGiftCard(row)
		},
		prepTotals() {
			if(
				(
					typeof(this.summaryCart.summary_section_totals) != 'undefined'
					&& typeof(this.summaryCart.summary_section_totals.promo_discounts) != 'undefined'
					&& this.summaryCart.summary_section_totals.promo_discounts
					&& typeof(this.summaryCart.summary_section_totals.promo_discounts.promo_discounts) != 'undefined'
					&& this.summaryCart.summary_section_totals.promo_discounts.promo_discounts > 0
				)
				||
				(
					typeof (this.summaryCart.shipping_discount) !== 'undefined'
					&& this.summaryCart.shipping_discount > 0
				)
			){
				if(typeof(this.summaryCart.summary_section_totals.line_savings) == 'undefined') {
					this.summaryCart.summary_section_totals.line_savings = 0;
				}
				this.summaryCart.summary_section_totals.display_line_savings = parseFloat(this.summaryCart.summary_section_totals.line_savings);
			}

			if(typeof(this.summaryCart.shipping_breakdown) != 'undefined' && this.summaryCart.shipping_breakdown['Shipping Upgrade'] > 0) {
				this.showShippingUpgrade = true;
			}
		}
	}
};
</script>

<style>
	.promo-code-description {
		padding: 10px;
	}
</style>
