import { defineStore } from 'pinia'
import axios from 'axios'
import { emitter } from '../main.js'
import { usePromoCodeStore } from './PromoCodeStore.js'
import { dayjs } from '../plugins/dayjs.js'

export const useCartStore = defineStore('CartStore', {
	state: () => {
		return {
			cartUpdateTimeStamp: '',
			cart: {},
			cartAddonQualify: false,
			passedCutoff: false,
			paypalStashedBillingAddress: {},
			billingSameAsShipping: false,
			recentlyViewedItems: [],
			smsOptInFlag: false,
			fieldErrors: {},
			QASComplete: false,
			hasAutoDelivery: false,
			cartCount: 0,
			miniCartItems: [],
			outOfStockItems: [],
			insufficientStockItems: [],
			insufficientStockError: false,
			cartStock: [],
			holidayExpeditedShippingMessage: null,
		}
	},
	
	actions: {
		calculateTax(page) {
			emitter.emit('cart-tax-caclculating')
			
			// Figure out the page we are on
			return axios.get('/api/checkout/calculate-tax/'+page)
			.then((response) => {
				this.cart = response.data.cart
				// this.updateOrderTotals(response.data.cart)
				emitter.emit('cart-tax-updated')

				return response.data
			})
			.catch((error) => {
				if(error.response?.data?.error && error.response?.data?.error == 'cart_not_found') {
					emitter.emit('cart-not-found')
				}
			})
		},
		async cartIsValid() {
			if(Object.keys(this.cart).length > 0 && this.cart.recipients?.length > 0) {
				// Cart in local storage appears valid - check the server/session
				return await axios.get('/api/checkout/quick-cart-check')
					.then((response) => {
						// Any 200 level response is good
						return true
					})
					.catch((error) => {
						return false
					})
			} else {
				return false
			}
		},
		// TODO: check to see if our store cart matches the server cart and update if needed
		updateCart(cart, type = '') {
			this.cart = cart
			this.updateOrderTotals(cart)
			emitter.emit('cart-updated', type)
			this.sortRecipientsAndLines()
			this.checkAddOnQualify()
			this.processSavedAddresses()
		},
		updateCartDataFromServer() {
			this.promoCodeStore = usePromoCodeStore()

			// Only add in the shipping and tax charges if we are on the billing page
			let pathname = window.location.pathname
			pathname = pathname.replace(/\/$/, "")
			let page = pathname.split("/").pop()

			let apiUrl = "/api/checkout/cart"
			if(page == 'billing') {
				apiUrl = "/api/checkout/cart?t=1"
			}
			return axios.get(apiUrl)
				.then((response) => {
					if(!response.data.cart || !response.data.cart.recipients || !response.data.cart.recipients.length) {
						let pathname = window.location.pathname
						pathname = pathname.replace(/\/$/, "")
						let page = pathname.split("/").pop()
						if(page == 'billing') {
							window.location.href = '/checkout/cart'
						}
					}
					// Replace `99999999` id for guest 'myself' address
					response.data.cart.saved_addresses = response.data?.cart?.saved_addresses?.map((address) => {
						if (!address.uuid) {
							address.uuid = 99999999
						}

						return address
					})

					this.cart = response.data.cart;
					this.cartAddonQualify = response.data.cart_addon_qualify
					this.passedCutoff = response.data.passed_cutoff
					this.cartTotals = response.data.cart.summary_section_totals
					this.sortRecipientsAndLines()
					this.setRecipFlags()
					this.nonPerishableCheck()
					this.checkCartStock()
					this.processSavedAddresses()
					this.checkGuaranteeDates()
					emitter.emit('cart-updated-from-server')
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
		},
		checkGuaranteeDates() {
			// Check if there is any guarantee date for the selected ship date of a recipient
			axios.get('/api/checkout/guarantee-dates')
				.then((response) => {

					this.getRecipients.map((recipientToUpdate) => {
						const updatedRecipientObject = {
							...recipientToUpdate,
							delivery_guarantee_data: (response.data.delivery_guarantee_data && recipientToUpdate.shipping.ship_select_type == 'week' && dayjs(response.data.delivery_guarantee_data.delivery_week).format('YYYY-MM-DD') == dayjs(recipientToUpdate.shipping.delivery_date).format('YYYY-MM-DD')) ? response.data.delivery_guarantee_data : null
						}

						this.updateRecipient(recipientToUpdate.crec_id, updatedRecipientObject)
					})
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
		},
		processSavedAddresses() {
			if(typeof(this.cart.saved_addresses) != 'undefined' && this.cart.saved_addresses.length) {
				for(var i = 0; i < this.cart.saved_addresses.length; i++) {
					var address = this.cart.saved_addresses[i]

					if (parseInt(address.is_myself) === 1) {
						address.selector_label = "Ship to Myself"

						// double check myself has an uuid
						if(!address.uuid) {
							address.uuid = 99999999
						}
					} else {
						if(!address.selector_label) {
							address.selector_label = address.fname+" "+address.lname
						}
					}
				}
			}
		},
		checkPromoCode() {
			// first check to see if we have a code in the store
			// second make sure that code is what is in the DB - if not update it
			this.promoCodeStore = usePromoCodeStore()
			if (!this.promoCodeStore.getCode && !this.cart.discount_code) {
				// no promo code in Store OR db
				return
			}
			// If the store has a code but the DB does not....clear the store
			if (this.promoCodeStore.getCode && !this.cart.discount_code) {
				this.promoCodeStore.clearCode()
			}

			// If both have a code, but they are not the same
			if(this.promoCodeStore.getCode && this.cart.discount_code && this.promoCodeStore.getCode !== this.cart.discount_code) {
				// update our store to match what is in the DB
				this.promoCodeStore.validateExistingPromoCode(this.cart.discount_code)
				return
			}
		},
		removePromoCode() {
			this.cart.discount_code = ''
			axios.delete('/api/checkout/discount')
			.then((response) => {
				// no need to return anything, just updating DB
			})
			.catch((error) => {
				this.$sentry.captureException(error)
			})
		},
		sortRecipientsAndLines() {
			var sortedRecips = []
			var notMyselfRecips = []

			// PUT THE "MYSELF" RECIPIENT FIRST AND BUILD AN ARRAY OF THE REST
			for(var i = 0; i < this.cart.recipients.length; i++) {
				var recip = this.cart.recipients[i]
				recip.sortLabel = typeof( recip.fname) !== 'undefined' ? (recip.fname ?? '') + ' ' + (recip.lname ?? '') : 'Myself'
				if(!recip.addr_id || (typeof(recip.is_myself) != 'undefined' && parseInt(recip.is_myself) === 1)) {
					// MYSELF shipment goes at the front
					recip.is_myself = '1'
					sortedRecips.unshift(recip)
				} else {
					notMyselfRecips.push(recip)
				}
			}
			// NOW sort notMyself by crec_id ( so that they have the same order that they were added )
			if(notMyselfRecips.length) {
				notMyselfRecips.sort((a, b) => (a.crec_id > b.crec_id) ? 1 : -1)
				sortedRecips = sortedRecips.concat(notMyselfRecips)
			}

			// NOW SORT THE ITEMS (FREE ITEMS AND ADD-ONs)
			for(var i = 0; i < sortedRecips.length; i++) {
				if(sortedRecips[i].items.length == 1) {
					if(sortedRecips[i].items[0].is_addon) {
						sortedRecips[i].items[0].showAsParentItem = 1
					}
					continue
				}
				var itemCLIN_IDs_in_recip = [];
				for ( var j = 0; j < sortedRecips[i].items.length ; j++ ) {
					itemCLIN_IDs_in_recip.push('--'+ sortedRecips[i].items[j].clin_id?.toString())
				}

				var parentItems = []
				var freeItems = []
				var addonItems = []
				var combinedSortedItems = []

				// 1st - Separate the parent, add-on and free items
				for(var j = 0; j < sortedRecips[i].items.length; j++) {
					var item = sortedRecips[i].items[j]

					var itemHasAParentID = (
						typeof( item.detail ) != 'undefined'
						&& item.detail
						&& typeof( item.detail.parent_clin_id ) != 'undefined'
					)
					var itemHasAValidParent = (
						itemHasAParentID
						&& itemCLIN_IDs_in_recip.includes('--'+ item.detail.parent_clin_id)
					)

					///  If we don't HAVE a parent...  Treat it AS a parent
					if(itemHasAParentID && !itemHasAValidParent) {
						item.showAsParentItem = 1
						parentItems.push(item)
					} else if ( ! itemHasAValidParent ) {
						// This could be a free item or a parent item
						if(this.cart.recalc_detail?.free_gift_fixed[item.recipient_guid]) {
							item.freeGiftItem = 1
							freeItems.push(item)
						} else {
							parentItems.push(item)
						}
					} else {
						// collect the addons
						addonItems.push(item)
					}
				}

				// NOW COMBINE
				for(var j = 0; j < parentItems.length; j++) {
					combinedSortedItems.push(parentItems[j])

					// Insert free gift items after 1st parent
					if(freeItems.length && i == 0) {
						for(var k = 0; k < freeItems.length; k++) {
							combinedSortedItems.push(freeItems[k])
						}
					}

					// Insert Add-on items to matching parent
					if(addonItems.length) {
						addonItems.map(item => {
							if(item.detail.parent_clin_id == parentItems[j].clin_id) {
								combinedSortedItems.push(item)
							}
						})
					}
				}

				sortedRecips[i].items = combinedSortedItems
			}

			this.cart.recipients = sortedRecips
		},
		nonPerishableCheck() {
			for(var i = 0; i < this.cart.recipients.length; i++) {
				if(typeof(this.cart.recipients[i].items) == 'undefined') { continue }

				this.cart.recipients[i].shippingMode = "nonPerishable"
				if (this.cart.recipients[i].cart_type == "P") {
					this.cart.recipients[i].shippingMode = "perishable"
				} 

				/// If we ARE in non-perishables mode, make sure we:
				///  1) HAVE a valid non-perishable ship method -- OR -- we set a default
				///  2) Set the ship-date to something that will default to "soonest-available" later-on
				///  NON-PERISHABLE -- set defaults and ship date token
				if ( this.cart.recipients[i].shippingMode == 'nonPerishable' && typeof(this.cart.recipients[i].items[0].nonperishable_ship_methods) != 'undefined' ) {
					if ( typeof this.cart.recipients[i].shipping.ORIGINAL_ship_date == 'undefined'
						&& typeof this.cart.recipients[i].shipping.ship_date != 'undefined'
					) {
						this.cart.recipients[i].shipping.ORIGINAL_ship_date = this.cart.recipients[i].shipping.ship_date
					}

					if(this.cart.recipients[i].shipping.ship_id != 24) {
						this.cart.recipients[i].shipping.ship_date = '||next-available||' // token read in "fuzzy" set method
					}
	
					var hasValidShipMethod = false
					var defaultMethod = null
					for ( var ii = 0; ii < this.cart.recipients[i].items[0].nonperishable_ship_methods.length ; ii++ ) {
						if ( typeof this.cart.recipients[i].shipping.ship_id != 'undefined'
							&& this.cart.recipients[i].shipping.ship_id
							&& this.cart.recipients[i].shipping.ship_id.toString() == this.cart.recipients[i].items[0].nonperishable_ship_methods[ii].ship_id.toString()
						) {
							hasValidShipMethod = true
						}
						if ( this.cart.recipients[i].items[0].nonperishable_ship_methods[ii].is_default ) {
							defaultMethod = this.cart.recipients[i].items[0].nonperishable_ship_methods[ii]
						}
					}
					///  Set default ship method - NEVER have a blank selectbox
					if ( !hasValidShipMethod && defaultMethod ) {
						this.cart.recipients[i].shipping.ship_id = defaultMethod.ship_id;
					}
				}
				/// PERISHABLE (possibly restore the ship-date)
				else if ( typeof this.cart.recipients[i].shipping.ORIGINAL_ship_date != 'undefined' ) {
					this.cart.recipients[i].shipping.ship_date = this.cart.recipients[i].shipping.ORIGINAL_ship_date;
				}
			}
		},
		updateRecipient(crec_id, updateData) {
			return new Promise((resolve) => {
				let updated = false
				this.cart.recipients = this.cart.recipients.map((recipient) => {
					if (recipient.crec_id === crec_id && updateData) {
						updated = true
						this.nonPerishableCheck()
						return {
							...recipient,
							...updateData,
						}
					} else {
						return recipient
					}
				})
	
				if(updated) {
					emitter.emit('cart-recipient-address-updated', {crec_id: crec_id})
				}

				resolve()
			})
		},
		createNewAddressRecord(fname, lname, crecId) {
			// Have the server create a new address with new addr_id
			axios.put('/api/checkout/pdp-add-new-recipient',{
				addr_id: '',
				fname: fname,
				lname: lname
			}).then(response => {
				// handle success
				this.cart.saved_addresses.push(response.data.newAddress);
				emitter.emit('ship-to-addr-id-changed', {uuid: response.data.newAddress.uuid, crec_id: crecId, is_new: true});
			})
			.catch(error => {
				emitter.emit('ship-to-somone-else-error', {error: error})	
			})
		},
		recipientChangeSavedAddress(crec_id, uuid, is_new) {
			// Requires full list of addresses,
			// crec_id for target recipient
			// and uuid to change to
			// FIRST Find out if this uuid is already on another shipment

			/**
			 * editShipment is the shipment getting edited
			 * newAddress is the new ship to person to receive it
			*/

			let newAddress = {}
			let editShipment = this.cart.recipients.find(recip => recip.crec_id == crec_id)

			newAddress = this.cart.saved_addresses.find((addr) => addr.uuid === uuid)

			let existingShipment
			// see if the newAddress has an existing shipment so we can merge them
			if(typeof(newAddress) !== 'undefined' && newAddress.addr_id) {
				existingShipment = this.cart.recipients.find(recip => recip.addr_id == newAddress.addr_id)
			}

			if (typeof(existingShipment) != 'undefined' && existingShipment?.crec_id != crec_id && existingShipment?.items?.length > 0) {
				// Found existing shipment with matching address - move items
				return this.recipientChangeMergeShipments(editShipment, existingShipment)

			} else if (typeof(newAddress) != 'undefined' && newAddress.uuid === 99999999) {
				// myself as a guest - if logged in the above if block will work
				// existingShipment doesn't exist so let's find the NULL addr_id entries
				existingShipment = this.cart.recipients.find(recip => recip.addr_id == null)

				if (typeof(existingShipment) != 'undefined' && existingShipment.crec_id !== editShipment.crec_id && existingShipment?.items?.length > 0) {
					return this.recipientChangeMergeShipments(editShipment, existingShipment)
				} else {
					editShipment.addr_id 		= newAddress.addr_id
					editShipment.fname 			= newAddress.fname
					editShipment.lname 			= newAddress.lname
					editShipment.company 		= newAddress.company
					editShipment.address_1 		= newAddress.address_1
					editShipment.address_2 		= newAddress.address_2
					editShipment.city 			= newAddress.city
					editShipment.state 			= newAddress.state
					editShipment.postal_code 	= newAddress.postal_code
					editShipment.phone 			= newAddress.phone
					editShipment.email 			= newAddress.email
					editShipment.is_myself		= newAddress.is_myself
					editShipment.res_com		= newAddress.res_com ? newAddress.res_com : 'V'
				}
			} else {

				// No existing shipment with matching address - UPDATE address info on editShipment
				if(typeof(newAddress) != 'undefined' && newAddress.uuid == uuid) {
					// Found the target recip AND the new address - UPDATE
					editShipment.addr_id 		= newAddress.addr_id
					editShipment.fname 			= newAddress.fname
					editShipment.lname 			= newAddress.lname
					editShipment.company 		= newAddress.company
					editShipment.address_1 		= newAddress.address_1
					editShipment.address_2 		= newAddress.address_2
					editShipment.city 			= newAddress.city
					editShipment.state 			= newAddress.state
					editShipment.postal_code 	= newAddress.postal_code
					editShipment.phone 			= newAddress.phone
					editShipment.email 			= newAddress.email
					editShipment.is_myself		= newAddress.is_myself
					editShipment.res_com		= newAddress.res_com ? newAddress.res_com : 'V'

					if(editShipment.announce_it_email) {
						editShipment.announce_it_email = newAddress.email
					}
				}
			}

			emitter.emit('cart-recipient-address-updated', {crec_id: crec_id})

			// NOTIFY THE SERVER
			return axios.post('/api/checkout/change-shipment-address', {cart_id: this.cart.cart_id, crec_id: crec_id, uuid: uuid})
				.then(response => {
					this.updateCart(response.data.cart)

					return response
				})
				.catch(error => {
					this.$sentry.captureException(error)
				})
		},
		recipientChangeMergeShipments(editShipment, existingShipment) {
			// loop over items on editShipment

			if (editShipment) {
				for (var i = 0; i < editShipment.items.length; i++) {
					var found = false;
					// Prod does NOT currently merge the items - this has some bugs with it too - price changes, add-on get's moved
					// Now loop over items on existingShipment to look for same item
					// for (var j = 0; j < existingShipment.items.length; j++) {
					// 	if (typeof (editShipment.items[i]) != 'undefined' && editShipment.items[i].item_id == existingShipment.items[j].item_id) {
					// 		// found matching item_id - increment the qty
					// 		existingShipment.items[j].qty = parseInt(existingShipment.items[j].qty) + parseInt(editShipment.items[i].qty)
					// 		this.updateItemQty(existingShipment.items[j], existingShipment.items[j].qty, existingShipment.crec_id)
					// 		found = true;
					// 	}
					// }
	
					if (!found) {
						// existingShipment does not have the same item - MOVE IT
						editShipment.items[i].crec_id = existingShipment.crec_id
						existingShipment.items.push(editShipment.items[i])
					}
				}
			}

			// Now delete the editShipment
			this.cart.recipients = this.cart.recipients.filter((recipient) => {
				if (recipient.crec_id !== editShipment.crec_id) return true

				// If deleted shipment has a free item, assign it to the new shipment
				if (this.cart.recalc_detail?.free_gift_fixed[editShipment.recipient_guid]) {
					delete Object.assign(this.cart.recalc_detail.free_gift_fixed, {
						[existingShipment.recipient_guid]: this.cart.recalc_detail.free_gift_fixed[editShipment.recipient_guid]
					})[editShipment.recipient_guid]
				}

				return false
			})

			// NOTIFY THE SERVER - ?
			return axios.post('/api/checkout/merge-shipments', { cart_id: this.cart.cart_id, winner: existingShipment.crec_id, loser: editShipment.crec_id })
				.then(response => {
					this.updateCart(response.data.cart)
				})
				.catch(error => {
					this.$sentry.captureException(error)
				})
		},
		recipientChangeShipDate(recipient, newShipDateData, params = {}) {
			// First clear out any delivery_guarantee_data - we'll check again after the change
			recipient.delivery_guarantee_data = null

			if (recipient.cart_type === 'ECRT' && recipient.shipping?.pickup_time_central) {
				newShipDateData.pickup_time_central = recipient.shipping.pickup_time_central
			}

			// Now update the server
			axios.post('/api/checkout/change-recipient-shipping', {
				crec_id: recipient.crec_id,
				new_ship_date_data: newShipDateData,
				...params,
			}).then(response => {
				let timestamp = `${newShipDateData.day}T12:00:00Z`

				if (params.cart_type === 'ECRT' && newShipDateData.pickup_time_central.toLowerCase() !== 'send immediately') {
					const time = dayjs(newShipDateData.pickup_time_central.replace(' Central', ''), 'h:mm a').format('HH:mm:ss')
					timestamp = dayjs.tz(`${newShipDateData.day} ${time}`, 'YYYY-MM-DD HH:mm:ss', 'America/Chicago').utc().format()
				}

				if (params.apply_all) {
					this.getRecipients.filter(
						(recipientToFilter) => recipientToFilter.cart_type === params.cart_type
					).map((recipientToUpdate) => {
						const updatedRecipientObject = {
							...recipientToUpdate,
							shipping: {
								...(recipientToUpdate.shipping || {}),
								delivery_date: timestamp,
								ship_date: timestamp,
								ship_id: newShipDateData.best_ship_id,
								ship_select_type: params.ship_select_type,
							},
							ship_calendar_group_data: response.data.ship_calendar_group_data || null,
							delivery_guarantee_data: response.data.delivery_guarantee_data || null
						}

						if (params.cart_type === 'ECRT') {
							updatedRecipientObject.shipping.pickup_time_central = newShipDateData.pickup_time_central
						}

						this.updateRecipient(recipientToUpdate.crec_id, updatedRecipientObject)
					})
				} else {
					const overnightUpgradeParams = {}

					if (response.data.overnight_upgrade_cost && response.data.overnight_upgrade_cost.upgrade_cost > 0) {
						overnightUpgradeParams.overnight_upgrade = response.data.overnight_upgrade
						overnightUpgradeParams.overnight_upgrade_cost = response.data.overnight_upgrade_cost.upgrade_cost
						overnightUpgradeParams.show_overnight_upgrade = response.data.show_overnight_upgrade
					}

					this.updateRecipient(recipient.crec_id, {
						...recipient,
						shipping: {
							...(recipient.shipping || {}),
							delivery_date: timestamp,
							ship_date: timestamp,
							ship_id: newShipDateData.best_ship_id,
							ship_select_type: params.ship_select_type,
							...overnightUpgradeParams,
						},
						ship_calendar_group_data: response.data.ship_calendar_group_data || null,
						delivery_guarantee_data: response.data.delivery_guarantee_data || null
					})
				}

				this.checkGuaranteeDates()
			})
			.catch(error => {
				this.$sentry.captureException(error)
			})
		},
		async recipientChangeGiftMessages({
			crec_id,
			...payload
		}) {
			// FIND OUR RECIPIENT
			let giftMessageData = {
				timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				gift_message: payload.gift_message,
				announce_it_message: payload.announce_it_message,
				announce_it_email: payload.announce_it_email,
				announce_it_time: payload.announce_it_time,
				announce_it_send_flag: payload.announce_it_send_flag,
				is_a_gift: payload.is_a_gift,
			}
			
			try {
				const response = await axios.post('/api/checkout/change-recipient-gift-message', {crec_id: crec_id, message_data: giftMessageData})

				if (response.data.status === 'success') {
					return await this.updateRecipient(crec_id, giftMessageData)
				}
			} catch (error) {
				console.log(error)
			}
		},
		async applyGiftMessageToAll({
			gift_message,
			announce_it_message,
			announce_it_email,
			announce_it_time,
			announce_it_send_flag,
			is_a_gift,
		}) {
			// This method does not send data to the server.  This only updates local storage after the server has updated 
			// the gift message info there.

			return await Promise.all(this.cart.recipients.map(
				(recipient) => this.recipientChangeGiftMessages({
					crec_id: recipient.crec_id,
					gift_message,
					announce_it_message,
					announce_it_email,
					announce_it_time,
					announce_it_send_flag,
					is_a_gift,
				}))
			)
		},
		setRecipFlags() {
			// ALLOW CUSTOMER TO APPLY DELIVERY DATE TO ALL SHIPMENTS
			// Adding in check for muliple perishable recipients
			// and / or mulitple eCertificate recipients
			let show_apply_all_perishable = false;
			let show_apply_all_ecrt = false;
			let perishableRecipCount = 0;
			let eCertRecipCount = 0;
			for(let i = 0; i < this.cart.recipients.length; i++) {
				let recipHasPerishable = false;
				let recipHasEcert = false;
				for(let j = 0; j < this.cart.recipients[i].items.length; j++) {
					if(this.cart.recipients[i].items[j].cart_type == "P") {
						recipHasPerishable = true;
					}

					if(this.cart.recipients[i].items[j].item_id == "ECRT") {
						recipHasEcert = true;
					}
				}

				if(recipHasPerishable) {
					perishableRecipCount++;
				}

				if(recipHasEcert) {
					eCertRecipCount++;
				}
			}

			// Now set the flags on each recipient for showing the apply-all checkbox
			for(var i = 0; i < this.cart.recipients.length; i++) {
				// Perishable Apply All Flag
				if(perishableRecipCount > 1) {
					this.cart.recipients[i].show_apply_all_perishable = true;
				} else {
					this.cart.recipients[i].show_apply_all_perishable = false;
				}
				
				// E-Certificate Apply All Flag
				if(eCertRecipCount > 1) {
					this.cart.recipients[i].show_apply_all_ecrt = true;
				} else {
					this.cart.recipients[i].show_apply_all_ecrt = false;
				}

				// Is the current Apply All checkbox checked
				this.cart.recipients[i].apply_all_checked = false;
			}
		},
		async cartPageFinalValidation() {
			// FIRST check stock
			let stockCheckProblem = false
			let cartItems = this.getSkuIds
			
			if(cartItems.length > 0) {
				let outOfStockFlag = false
				let insuffcientStockItems = []
				stockCheckProblem = await axios.post('/api/checkout/check-stock-on-cart/', cartItems)
											.then((response) => {
												response.data.map((item) => {
													if(item.units <= 0) {
														this.outOfStockItems.push(item.item_id)
														outOfStockFlag = true
													} else {
														// Not out of stock - but do we have sufficient stock?
														if(item.units < this.itemUnitsInCart(item.item_id)) {
															insuffcientStockItems.push({item_id: item.item_id, units: item.units})
														}
													}
												})

												if(outOfStockFlag || insuffcientStockItems.length) {
													return true
												}

												return false
											})

				if(stockCheckProblem) {
					if(outOfStockFlag) {
						return {
							status: 'failed',
							reason: 'item-out-of-stock'
						}
					}

					if(insuffcientStockItems.length) {
						return {
							status: 'failed',
							reason: 'insufficient-stock',
							itemList: insuffcientStockItems
						}
					}
				}
			}


			// NEXT check shipments with add-ons still qualify
			let addonProblem = false
			for(var i = 0; i < this.cart.recipients.length; i++) {
				var shipmentQualifyingTotal = 0
				var shipmentHasAddon = false
				var shipmentAddonParent = false
				for(var j = 0; j < this.cart.recipients[i].items.length; j++) {
					// Is this a perishable non-addon
					if (this.cart.recipients[i].items[j].cart_type == "P" && !this.cart.recipients[i].items[j].is_addon && this.cart.recipients[i].items[j].price > 59.95) {
						shipmentAddonParent = true;
					}

					// Is this an addon?
					if(this.cart.recipients[i].items[j].is_addon) {
						shipmentHasAddon = true
					} else {
						shipmentQualifyingTotal += this.cart.recipients[i].items[j].display_totals.display_price * this.cart.recipients[i].items[j].qty
					}
				}

				if(shipmentHasAddon && !shipmentAddonParent && shipmentQualifyingTotal < 59.95) {
					addonProblem = true
				}
			}

			if(addonProblem) {
				return {
					status: 'failed',
					reason: 'add-on-qualify'
				}
			}

			// NEXT check auto-delivery - to ensure at least 1 qualifying item
			let adCheckPassed = true
			this.cart.recipients.map(recip => {
				let recipHasQualifier = false
				let recipHasAutoDelivery = false
				recip.items.map(item => {
					if(item.auto_delivery_eligible && item.is_perpetual_subscription) {
						recipHasQualifier = true
					} else if(item.is_perpetual_subscription) {
						recipHasAutoDelivery = true
					}
				})

				if(recipHasAutoDelivery && !recipHasQualifier) {
					adCheckPassed = false
					return
				}
			})

			if(!adCheckPassed) {
				return {
					status: 'failed',
					reason: 'auto-delivery-qualify'
				}
			}

			// NO PROBLEMS DETECTED - CART IS VALID
			return {
				status: 'valid'
			}

			return {
				status: 'valid'
			}
		},
		itemUnitsInCart(itemId) {
			let units = 0
			this.cart.recipients.map(recip => {
				recip.items.map(item => {
					if(item.item_id == itemId) {
						units += parseInt(item.qty)
					}
				})
			})

			return units
		},
		shippingPageFinalValidation() {
			// GET ALL VALIDATION ERRORS FROM SERVER
			return axios.get('/api/checkout/validation-check')
		},
		updateBillingAddress(data) {
			return axios.put('/api/checkout/billing-address/', data)
		},
		placeOrder(data) {
			emitter.emit('fire-record-checkout-decision-event', {
				step: 'paymentStep',
				type:data.payment_info.type
			})
			return axios.put('/api/checkout/place-order/', { payment_info: Object.assign({}, data.payment_info), sms_info: data.sms_info })
		},
		completeCart() {
			this.cart = {}
			this.cartUpdateTimeStamp = ''
			this.cartAddonQualify = false
			this.passedCutoff = false
			this.paypalStashedBillingAddress = {}
			this.billingSameAsShipping = false
			this.recentlyViewedItems = []
			this.smsOptInFlag = false
			this.fieldErrors = {}
			this.QASComplete = false
			this.hasAutoDelivery = '0'
			this.cartCount = 0
			this.miniCartItems = []
			this.outOfStockItems = []
			this.insufficientStockItems = []
			this.insufficientStockError = false
			this.cartStock = []
		},
		async updateItemQty(item, newQty, recipientId) {
			// if qty is zero or less, just remove the item from the cart
			if(newQty <= 0) {
				this.removeLineItem(item, recipientId)
			} else {
				// check stock on this item - if no stock, keep their same qty and show the stock error
				await axios.get('/api/checkout/check-sku-stock/' + item.sku_id)
				.then(async (response) => {
					// response.data returns boolean
					if (!response.data) {
						// out of stock
						if (!this.checkIfItemOutOfStock(item.item_id)) {
							this.outOfStockItems.push(item.item_id)
						}
						emitter.emit('item-out-of-stock', item)
						emitter.emit('hide-loading-overlay')
						return
					} else {
						// in stock
						// update qty on item
						const newTempCart = this.cart
						const newTotals = {}

						const tempRecipientIndex = newTempCart.recipients.findIndex(
							(recipient => recipient.crec_id === recipientId)
						)
						if (tempRecipientIndex >= 0) {
							// found the recipient the item belongs to, now update the item
							const tempItemIndex = newTempCart.recipients[tempRecipientIndex].items.findIndex(
								(line => line.clin_id === item.clin_id)
							)
							if (tempItemIndex >= 0) {
								newTempCart.recipients[tempRecipientIndex].items[tempItemIndex].qty = newQty
								// ------------------- BUG FIX HERE
								newTempCart.recipients[tempRecipientIndex].items[tempItemIndex].qty = newQty
								newTotals.display_price = item.display_totals.display_price
								newTotals.strike_price = item.display_totals.strike_price
								newTotals.display_subtotal = (item.display_totals.display_price * newQty)
								newTotals.strike_subtotal = (item.display_totals.strike_price * newQty)
								newTempCart.recipients[tempRecipientIndex].items[tempItemIndex].display_totals = newTotals
							}
						}

						// Notify the server
						await axios.put('/api/checkout/line-item-quantity/', {
							'clin_id': item.clin_id,
							'qty': newQty
						})
							.then((response) => {
								// clear the insufficientStock error just in case
								emitter.emit('sufficient-stock', {
									itemId: item.item_id,
									units: response.data.units_available
								})
								emitter.emit('item-back-in-stock', {
									itemId: item.item_id,
									units: response.data.units_available
								})

								this.updateCart(response.data.cart)
							})
							.catch((error) => {
								// item out of stock
								if (error.response.data.units_available === 0) {
									// trigger out of stock message
									emitter.emit('item-out-of-stock', {
										itemId: item.item_id,
										units: error.response.data.units_available
									})
								}
								// item insufficient stock
								if (error.response.data.units_available < newQty) {
									this.insufficientStockError = true
									emitter.emit('insufficient-stock', {
										itemId: item.item_id,
										units: error.response.data.units_available
									})
								}
							})
					}
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
				.finally(() => {
					
				})
			}
		},
		removeLineItem(item, recipientId) {
			// if they are removing an item, we can assume there are items in the cart already
			let newTempCart = this.cart
			let itemRemoved
			let newParentItemClinId

			const tempRecipientIndex = newTempCart.recipients.findIndex(
				(recipient => recipient.crec_id === recipientId)
			)

			if (tempRecipientIndex >= 0) {
				// found the recipient the item belongs to, now remove the item
				const tempItemIndex = newTempCart.recipients[tempRecipientIndex].items.findIndex(
					(line => line.clin_id === item.clin_id)
				)
				if(tempItemIndex >= 0) {
					itemRemoved = newTempCart.recipients[tempRecipientIndex].items[tempItemIndex]
					newTempCart.recipients[tempRecipientIndex].items.splice(tempItemIndex, 1)

					let recipGuid = newTempCart.recipients[tempRecipientIndex].recipient_guid

					// Clear any PROMO discounts associated with deleted line
					if(typeof(newTempCart.recalc_detail.discount_detail[recipGuid]) != 'undefined' && typeof(newTempCart.recalc_detail.discount_detail[recipGuid].lines) != 'undefined') {
						let discLineID = null
						for(var i = 0; i <= Object.keys(newTempCart.recalc_detail.discount_detail[recipGuid].lines).length; i++) {
							discLineID = 1000 + i + 1
							if(newTempCart.recalc_detail.discount_detail[recipGuid]?.lines[discLineID]?.clin_id == itemRemoved.clin_id) {
								break
							}
						}

						if(typeof(newTempCart.recalc_detail.discount_detail[recipGuid].lines[discLineID]) != 'undefined') {
							let fullRecipDiscAmt = newTempCart.recalc_detail.discount[recipGuid]
							let lineDiscAmt = newTempCart.recalc_detail.discount_detail[recipGuid].lines[discLineID].discount

							newTempCart.recalc_detail.discount[recipGuid] = fullRecipDiscAmt - lineDiscAmt
							delete newTempCart.recalc_detail.discount_detail[recipGuid].lines[discLineID]
						}

					}

					// Was this the last line on this shipment?
					if(newTempCart.recipients[tempRecipientIndex].items.length) {
						// NO...THERE ARE STILL MORE LINES

						// Now check to see if there was an add-on associated with this line and try to find a new parent
						// ignore if this is an addon or if the parent item has no addons
						newParentItemClinId = this.hasPossibleParentItem(recipientId, newTempCart.recipients[tempRecipientIndex].items[tempItemIndex])
						if (newParentItemClinId && !itemRemoved?.is_addon) {
							// assign (map) addons to new parent
							this.checkForChildren(itemRemoved.clin_id, tempRecipientIndex, newParentItemClinId)
						}
						// update mini cart info
						this.cartCount = this.cartCount - 1
						const miniItemIndex = this.miniCartItems.findIndex(
							(miniItem => item.clin_id === miniItem.clin_id)
						)
						this.miniCartItems.splice(miniItemIndex, 1)
						emitter.emit('updated-cart-count')

						// if this item was in the outOfStockItems array, remove it
						const outOfStockItemIndex = this.outOfStockItems.findIndex(
							(outOfStockItem => itemRemoved.item_id === outOfStockItem)
						)
						if (outOfStockItemIndex >= 0) {
							this.outOfStockItems.splice(outOfStockItemIndex, 1)
						}
					} else {
						// YES - We just removed the last line for this shipment
						newTempCart = this.removeRecipientFromCart(newTempCart, newTempCart.recipients[tempRecipientIndex], tempRecipientIndex)
					}
				}				
			}

			this.updateCart(newTempCart)

			// send event to GTM/ListTrack
			emitter.emit('fire-remove-from-cart-event', {
				item
			})

			// Notify the server
			axios.delete('/api/checkout/line-item/' + item.clin_id)
				.then((response) => {
					this.updateCart(response.data.cart)
					emitter.emit('hide-loading-overlay')
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
		},
		async addSpecialItem(item, myself, combo = false) {
			const newTempCart = this.cart
			let itemAdded = ''
			const tempRecipientIndex = newTempCart.recipients.findIndex(
				(recipient => recipient.addr_id === myself)
			)
			if(tempRecipientIndex >= 0) {
				// combo items main image comes from their product_page table - we need to grab that for the image to show up correctly
				let itemImage = ''
				let comboItem = ''
				let prodId = ''
				let nonAddonItem
				let newParentClinId = ''
				let possibleParentItem
				let maxPricedItem
				let tempRecipTotal = 0
				if(item.product_images) {
					await axios.get('/api/checkout/getComboPrimaryProduct/' + item.sku_id)
					.then((response) => {
						// we call this api route because we need the primary image of the special item and the prod id
						itemImage = response.data.primary_image
						prodId = response.data.prod_id
					})
					await axios.get('/api/checkout/getComboPrimaryData/' + item.sku_id)
					.then((response) => {
						comboItem = response.data
					})
				} else {
					itemImage = item.product_image
				}

				// check to make sure they have multiple items over 59.95
				// it's not just one item > 59.95 - it's all the person's (Ship to) items total > 59.95
				newTempCart.recipients[tempRecipientIndex].items.map(item => {
					tempRecipTotal += (item.price * item.qty)
				})

				if(tempRecipTotal > 59.95) {
					emitter.emit('fire-add-cart-special-event', item)
					// notify server
					axios.put('/api/checkout/line-item/', { lineitems: [{ item_id: item.item_id, addr_id: myself, location: 'cart', cspl_id: this.cart.cspl_id }] })
						.then((response) => {
							this.updateCart(response.data.cart, 'singleSpecial')
						})
						.catch((error) => {
							this.$sentry.captureException(error)
						})
				}
			}

			itemAdded = item
			return true
		},

		removeRecipientFromCart(tempCart, recipient, recipientIndex) {
			// Clean up recalc_detail first
			let recipGuid = recipient.recipient_guid
			if(typeof(tempCart.recalc_detail.discount[recipGuid]) != 'undefined') {
				delete tempCart.recalc_detail.discount[recipGuid]
			}
			if(typeof(tempCart.recalc_detail.discount_detail[recipGuid]) != 'undefined') {
				delete tempCart.recalc_detail.discount_detail[recipGuid]
			}
			if(typeof(tempCart.recalc_detail.free_gift_fixed[recipGuid]) != 'undefined') {
				delete tempCart.recalc_detail.free_gift_fixed[recipGuid]
			}
			if(typeof(tempCart.recalc_detail.shipping[recipGuid]) != 'undefined') {
				delete tempCart.recalc_detail.shipping[recipGuid]
			}
			if(typeof(tempCart.recalc_detail.shipping_subtotal_breakdown[recipGuid]) != 'undefined') {
				delete tempCart.recalc_detail.shipping_subtotal_breakdown[recipGuid]
			}
			if(typeof(tempCart.recalc_detail.tax[recipGuid]) != 'undefined') {
				delete tempCart.recalc_detail.tax[recipGuid]
			}
			if(typeof(tempCart.recalc_detail.tax_rate[recipGuid]) != 'undefined') {
				delete tempCart.recalc_detail.tax_rate[recipGuid]
			}
			if(typeof(tempCart.recalc_detail.tax_system_used[recipGuid]) != 'undefined') {
				delete tempCart.recalc_detail.tax_system_used[recipGuid]
			}

			// Now remove the recipient
			if (recipient.items.length <= 0) {
				// quietly remove from cart
				tempCart.recipients.splice(recipientIndex, 1)
			}

			return tempCart
		},
		async skuChangeItem(newSku, currentLine, recipientId, newParentClinId = '') {
			// find current line on the recipient and replace it with the new sku
			// this happens behind the scenes and updates the cart almost immediatly
			const newTempCart = this.cart
			const tempRecipientIndex = newTempCart.recipients.findIndex(
				(recipient => recipient.crec_id === recipientId)
			)
			let parentClinId = ''
			let nonAddonPrice = null

			if(tempRecipientIndex >= 0) {
				// found the recipient the item belongs to, now update the item
				const tempItemIndex = newTempCart.recipients[tempRecipientIndex].items.findIndex(
					(line => line.clin_id === currentLine.clin_id)
				)
				if (tempItemIndex >= 0) {
					if (newParentClinId) {
						parentClinId = newParentClinId
						// if item is a Parent - re-assign it's children!
						this.checkForChildren(currentLine.clin_id, tempRecipientIndex, newParentClinId)
					} else {
						parentClinId = (currentLine.detail?.parent_clin_id && newSku.erp_is_addon) ?? ''
					}
					// if the newSKU is an add-on, grab the non-addon-item id and get the price for it.
					if (newSku.erp_is_addon) {
						await axios.get('/api/checkout/get-non-addon-item-price/' + newSku.non_addon_item)
						.then(response => {
							nonAddonPrice = response.data
						})
						.catch(error => {
							this.$sentry.captureException(error)
						})
					}

					newTempCart.recipients[tempRecipientIndex].items[tempItemIndex] = {
						"display_name": newSku.sku_name,
						"product_short_description": newSku.description,
						"clin_id": currentLine.clin_id,	// keeping the same clin_id since the backend uses the same one
						"item_id": newSku.item_id,
						"sku_id": newSku.sku_id,
						"price": newSku.regular_price,
						"qty": currentLine.qty,	// keep the same qty
						"is_addon": newSku.erp_is_addon,
						"sku_name": newSku.sku_name,
						"unit_count": newSku.unit_count,
						"cart_type": newSku.erp_cart_type,
						"does_ship": 1,
						"auto_delivery_eligible": newSku.auto_delivery_eligible,
						"original_price": newSku.price,
						"detail": {
							"parent_clin_id": parentClinId
						},
						"is_club": newSku.erp_is_club_item,
						"is_combo": newSku.attributes.is_combo,
						"sku_chooser_style": currentLine.sku_chooser_style,
						"num_of_siblings": (currentLine.num_of_siblings) ?? this.countSiblings(newSku.attributes?.combo_item_ids),
						"ships_free": newSku.erp_automatic_free_shipping,
						"product_name": currentLine.product_name,
						"product_prod_id": currentLine.product_prod_id,
						"product_image": currentLine.product_image,
						"product_url_id": currentLine.product_url_id,
						"display_totals": {
							"display_price": newSku.regular_price,
							"display_subtotal": (newSku.regular_price * currentLine.qty),
							"strike_price": nonAddonPrice,
							"strike_subtotal": (nonAddonPrice) ? (nonAddonPrice * currentLine.qty) : '',
						}
					}
				}

				this.updateCart(newTempCart)

				// clear the outof/insufficientStock error just in case
				this.checkCartStock()
				emitter.emit('sufficient-stock', {
					itemId: currentLine.item_id
				})
				emitter.emit('sufficient-stock', {
					itemId: newSku.item_id
				})
				emitter.emit('item-back-in-stock', {
					itemId: currentLine.item_id
				})
				emitter.emit('item-back-in-stock', {
					itemId: newSku.item_id
				})
				emitter.emit('cart-modified')

				emitter.emit('fire-change-line-sku-event', {
					currentLine,
					newSku
				})

				// notify server
				axios.put('/api/checkout/line-item-sku/', { clin_id: currentLine.clin_id, sku_id: newSku.sku_id })
				.then((response) => {
					// console.log('updated sku on server:', response.data)
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
			}
		},
		checkForChildren(clinId, recipientIndex, newParentClinId) {
			const newTempCart = this.cart
			newTempCart.recipients[recipientIndex].items.map(item => {
				if (item.is_addon) {
					if (newParentClinId !== item?.detail?.parent_clin_id) {
						item.detail.parent_clin_id = newParentClinId
						// notify the server
						axios.put('/api/checkout/update-parent-line-item/', { clin_id: item.clin_id, newParent: newParentClinId })
						.then((response) => {
							// console.log('updated sku on server:', response.data)
						})
						.catch((error) => {
							this.$sentry.captureException(error)
						})
					}
				}
			})
		},
		async getProdIdFromSkuId(skuId) {
			let prodId = ''
			await axios.get('/api/checkout/getProdIdFromSkuId/' + skuId)
			.then((response) => {
				prodId = response.data.prod_id
			})
			.catch((error) => {
				this.$sentry.captureException(error)
			})
			return prodId
		},
		countSiblings(siblings = '') {
			// siblings in the DB is a comma delimited string
			return siblings.split(',').length
		},
		checkCartStock() {
			// getting item id from cart
			let items = []
			items = this.getSkuIds

			if(items.length > 0) {
				axios.post('/api/checkout/check-stock-on-cart/', items)
				.then((response) => {
					// storing the items and their stock levels
					this.cartStock = []
					// console.log('checkStock data:', response.data)
					response.data.map((count) => {
						this.cartStock.push(count)
						// console.log('count:', count.units)
						if(count.units <= 0) {
							if (!this.checkIfItemOutOfStock(count.item_id)) {
								this.outOfStockItems.push(count.item_id)
							}
							emitter.emit('item-out-of-stock', {
								itemId: count.item_id,
								units: count.units
							})
						} else {
							// check to see if this item is still out of stock
							if (this.checkIfItemOutOfStock(count.item_id)) {
								this.outOfStockItems.splice(count, 1)
								emitter.emit('item-back-in-stock', {
									itemId: count.item_id
								})
							}
						}
					})
					// console.log('checkStock store:', this.cartStock)
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
			} else {
				// console.log('items is empty')
			}
		},
		getItemIds() {
			let recipients = this.cart.recipients
			let itemsArray = []
			if (this.cart?.recipients?.length > 0) {
				recipients.map((recipient) => {
					recipient.items.map((item) => {
						itemsArray.push(item.item_id)
					})
				})
			}
			return itemsArray
		},
		updateOrderTotals(newCart) {
			// console.log('updating totals - updateOrderTotals:')
			// console.log(newCart.summary_section_totals)
			// going to calc the summary section here

			var strikeSubtotal = 0
			var itemDiscounts = 0
			var freeShipCount = 0

			if(newCart.recipients?.length > 0) {
				newCart.recipients?.map((recipient) => {
					recipient.items?.map((item) => {
						// Add up the overall subtotal price
						if (item.display_totals.strike_price && item.display_totals.strike_price !== item.price) {
							strikeSubtotal += parseFloat(item.display_totals.strike_price) * parseInt(item.qty)
						} else {
							strikeSubtotal += parseFloat(item.display_totals.display_price) * parseInt(item.qty)
						}

						// Add up the item savings
						if (item.display_totals.strike_subtotal && item.display_totals.strike_subtotal >= 0 && item.display_totals.strike_subtotal != item.display_totals.display_subtotal) {
							itemDiscounts += parseFloat(item.display_totals.strike_subtotal) - parseFloat(item.display_totals.display_subtotal)
						}
					})

					// Add up promo-code ITEM discounts
					if(newCart.recalc_detail.discount_detail[recipient.recipient_guid]?.lines) {
						for(const lineIdx in newCart.recalc_detail.discount_detail[recipient.recipient_guid].lines) {
							if(parseFloat(newCart.recalc_detail.discount_detail[recipient.recipient_guid].lines[lineIdx].discount) > 0) {
								itemDiscounts += parseFloat(newCart.recalc_detail.discount_detail[recipient.recipient_guid].lines[lineIdx].discount)
							}
						}
					}

					// FREE ITEMS Are not in the cart....just in the discount detail
					newCart.recalc_detail.free_gift_fixed[recipient.recipient_guid]?.map((freeItem) => {
						itemDiscounts += parseFloat(freeItem.display_totals.strike_price)
						strikeSubtotal += parseFloat(freeItem.display_totals.strike_price)
					})
				})

				// free shipping?
				newCart.recipients?.map((recipient) => {
					let tempFreeShipCount = 0	// this keeps one free shipping item per recipient
					recipient.items?.map((item) => {
						if (item.ships_free && tempFreeShipCount <= 0) {
							tempFreeShipCount++
							freeShipCount++
							// only one free item per recipient
						}
					})
				})
			}

			let shippingDiscount = 0
			if(newCart.shipping_breakdown['Shipping Discount']) {
				shippingDiscount = newCart.shipping_breakdown['Shipping Discount']
			}

			// TODO: figure out free gift items? Cart.php line 3382

			// new summary object
			let tempSummarySection = {}
			tempSummarySection.promo_discounts = {}

			tempSummarySection.strike_subtotal = strikeSubtotal
			tempSummarySection.line_savings = itemDiscounts

			tempSummarySection.saved_over_total = (itemDiscounts + shippingDiscount)

			tempSummarySection.promo_discounts.shipping_discount = newCart.shipping_breakdown['Shipping Discount'] ? shippingDiscount : 0
			tempSummarySection.promo_discounts.promo_code = newCart.discount_code
			tempSummarySection.promo_discounts.discount_amount = itemDiscounts
			tempSummarySection.promo_discounts.amount = (itemDiscounts + shippingDiscount)
			tempSummarySection.promo_discounts.promo_discounts = itemDiscounts

			tempSummarySection.before_shipping_subtotal = (strikeSubtotal - itemDiscounts)

			if (tempSummarySection.promo_discounts.amount === 0) {
				tempSummarySection.promo_discounts.shipping_discount = 0
			}

			tempSummarySection.cart_net_total = parseFloat(tempSummarySection.before_shipping_subtotal)

			// Only add in the shipping and tax charges if we are on the billing page
			let pathname = window.location.pathname
			pathname = pathname.replace(/\/$/, "")
			let page = pathname.split("/").pop()
			if(page == 'billing') {
				tempSummarySection.cart_net_total += parseFloat(newCart.shipping_breakdown['Standard Shipping'])
				tempSummarySection.cart_net_total += (newCart.shipping_breakdown['Shipping Upgrade'] ? parseFloat(newCart.shipping_breakdown['Shipping Upgrade']) : 0)
				tempSummarySection.cart_net_total -= parseFloat(tempSummarySection.promo_discounts.shipping_discount)
				tempSummarySection.cart_net_total += parseFloat(newCart.tax)
			}

			// check for gift cards
			if(newCart.gift_card_json && Object.entries(newCart.gift_card_json).length > 0) {
				for (const [key, gc] of Object.entries(newCart.gift_card_json)) {
					if(tempSummarySection.cart_net_total - gc.applied_amount < 0 ) {
						// The total must have decreased....this applied amount would send us below zero...adjust
						gc.applied_amount = tempSummarySection.cart_net_total
						tempSummarySection.cart_net_total = 0
					} else if(tempSummarySection.cart_net_total - gc.applied_amount > 0 && gc.applied_amount < parseFloat(gc.balance)) {
						// The total has increased...see if there is more balance to be applied
						gc.applied_amount = parseFloat(gc.balance)
						tempSummarySection.cart_net_total -= gc.applied_amount
					} else {
						tempSummarySection.cart_net_total -= gc.applied_amount
					}
				}
			}

			this.cart.summary_section_totals = tempSummarySection
			// console.log(tempSummarySection)
		},
		hasPossibleParentItem(recipientId, line) {
			// line is the new pending update line we need to ignore because we will be 
			// checking to see if there is a parent item for this recipient
			// we also need to find the possible parent line that has the biggest price
			const tempCart = this.cart
			const tempRecipientIndex = tempCart.recipients.findIndex(
				(recipient => recipient.crec_id === recipientId)
			)
			if (tempRecipientIndex >= 0) {
				// filter the array of the non-add on items and ignore the item clicked
				let possibleParentItems = tempCart.recipients[tempRecipientIndex].items.filter(item => (item.clin_id !== line?.clin_id && !item.is_addon && Number(item.price * item.qty) >= 59.95))

				if (possibleParentItems.length > 0) {
					let test = possibleParentItems.reduce(
						(max, min) => Number(max.price) > Number(min.price) ? max : min
					)
					if(test.clin_id) {
						return test.clin_id
					}
				}
				return false
			}
		},
		removeGiftCard(row) {
			if (this.cart.gift_card_json[row.certificate_num]) {
				axios.delete('/api/checkout/giftcard/' + row.certificate_num + '/billing')
				.then(response => {
					if (response.data.status == 'success') {
						this.updateCartDataFromServer()
						.then((response) => {
							emitter.emit('gift-card-removed')
						})
					}
				})
				.catch(error => {
					this.$sentry.captureException(error)
					emitter.emit('gc-remove-failed')
				})
			} else {
				// error?
				this.$sentry.captureException(error)
				emitter.emit('gc-remove-failed')
			}
		},
		checkAddOnQualify() {
			// this just does a check to see if the cart has a non-addon item]
			let qualify = 0
			this.cartAddonQualify = false
			this.cart.recipients.map(recip => {
				let tempRecipTotal = 0
				// it's not just one item > 59.95 - it's all the person's (Ship to) items total > 59.95
				recip.items.map(item => {
					tempRecipTotal += (item.price * item.qty)
				})
				if(tempRecipTotal > 59.95) {
					qualify++
				}
			})
			if(qualify > 0) {
				// global check to show the in cart specials or not
				this.cartAddonQualify = true
			} else {
				this.cartAddonQualify = false
			}
		},
		updateFixedClubItem(line, newLineData) {
			const newTempCart = this.cart
			newTempCart.recipients.map((recipient, key) => {
				recipient.items.map((item, iKey) => {
					if(item.clin_id == line.clin_id) {
						newTempCart.recipients[key].items[iKey].detail.club_orders = newLineData.club_orders
						this.updateCart(newTempCart)
						// notify server
						axios.post('/api/checkout/line-item-club-edit', {
							clin_id: line.clin_id,
							detail: newLineData
						})
						.then(response => {
							// don't need to do anything
							// console.log('update fixed item response:', response)
						})
						.catch(error => {
							this.$sentry.captureException(error)
						})
					}
				})
			})
		},
		getCartItemCount() {
			// getting the mini cart data
			axios.get('/api/checkout/regen-mini-cart/')
				.then((response) => {
					this.cartCount = response.data.mini_cart.length
					this.miniCartItems = response.data.mini_cart
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
			return this.cartCount
		},
		checkIfItemOutOfStock(itemId) {
			let found = false
			return found = this.outOfStockItems.find((item) => {
				return item === itemId
			})
		},

		// ---------------------------------------------------------
		// Utility functions not actually related to the cart object
		// ---------------------------------------------------------
		updateRecentlyViewedItems() {
			// This might need to go into an Items or Product store at a later time
			axios.get('/api/checkout/recently-viewed-items')
				.then((response) => {
					this.recentlyViewedItems = response.data
					if (!this.recentlyViewedItems) {
						this.recentlyViewedItems = []
					}
					if (this.recentlyViewedItems && this.recentlyViewedItems.length > 4) {
						this.recentlyViewedItems = this.recentlyViewedItems.slice(-4)	// only grab the last 4 items
					}
				})
		},
		shipmentAutoDelivery(crecId, frequency) {
			this.fullCart.recipients.map(recipient => {
				if(recipient.crec_id == crecId) {
					recipient.items.map(item => {
						if(!item.detail.perpetual_subscription?.manually_removed)
							item.detail.perpetual_subscription = {
								interval_months: frequency,
								manually_removed: false
							}
					})
				}
			})

			let _this = this
			axios.put('/api/checkout/shipment-auto-delivery', { crec_id: crecId, selected_frequency: frequency })
				.then((response) => {
					if(response.data.status == 'success') {
						
					}
				})
				.finally(function(){
					emitter.emit('shipment-auto-delivery-updated', {selectedFrequency: frequency, crec_id: crecId})
				})
		},
		lineAutoDelivery(clinId, frequency) {
			let _this = this
			axios.put('/api/checkout/line-auto-delivery', { clin_id: clinId, selected_frequency: frequency })
			.then((response) => {
				if(response.data.status == 'success') {
					// In case this was a removal...was this the only (or last) item in the auto-delivery?
					let stillHasAutoDelivery = false
					let crecId = ''
					_this.cart.recipients.map(recipient => {
						recipient.items.map(item => {
							if(item.clin_id == clinId) {
								crecId = recipient.crec_id
								if(frequency != '0') {
									if(!item.detail.perpetual_subscription) {
										item.detail.perpetual_subscription = {
											interval_months: frequency,
											manually_removed: false
										}
									} else {
										item.detail.perpetual_subscription.interval_months = frequency
										item.detail.perpetual_subscription.manually_removed = false
									}
								} else {
									item.detail.perpetual_subscription.interval_months = '0'
									item.detail.perpetual_subscription.manually_removed = true
								}
							}

							if(item.detail?.perpetual_subscription?.interval_months != '0') {
								stillHasAutoDelivery = true
							}
						})
					})

					emitter.emit('line-auto-delivery-updated', { lineId: clinId, lineStillHasAutoDelivery: stillHasAutoDelivery })
					if(!stillHasAutoDelivery) {
						emitter.emit('shipment-auto-delivery-updated', {crec_id: crecId, selectedFrequency: 0})
					}
				}
			})

		},
		lineInAutoDeliveryShipment(clinId) {
			var returnVal = false
			for(var i = 0; i < this.cart?.recipients?.length; i++ ) {
				for(var j = 0; j < this.cart.recipients[i].items.length; j++ ) {
					if(this.cart.recipients[i].items[j].clin_id == clinId) {
						if(
							typeof(this.cart.recipients[i].items[j].detail.perpetual_subscription) != 'undefined'
							&& typeof(this.cart.recipients[i].items[j].detail.perpetual_subscription.interval_months) != 'undefined'
							&& this.cart.recipients[i].items[j].detail.perpetual_subscription.interval_months != '0'
						) {
							returnVal = true
						}
					}
				}
			}

			return returnVal
		},

		trackCrossSellImpression(type) {
			if (!Object.values(this.$constants).includes(type)) {
				this.$sentry.captureException(new Error('Cross-sell impression has no defined type'))
				return
			}

			if (!this.cart.cart_specials) {
				this.$sentry.captureException(new Error('No cart specials found for cross-sell impression'))
				return
			}

			const skuIds = this.cart.cart_specials.skus.reduce((ids, { sku_id }) => {
				if (!ids.includes(sku_id)) {
					ids.push(sku_id)
				}

				return ids
			}, [])

			if (this.cart.cart_specials.combo) {
				skuIds.push(this.cart.cart_specials.combo.sku_id)
			}

			const buildTypeLabel = (type) => {
				const skuType = this.cart.cart_specials.skus.reduce((type, sku) => sku.type || type, '')

				if (!skuType) return type

				return `${type}-${skuType.charAt(0).toUpperCase() + skuType.slice(1)}`
			}

			return axios.get('/api/impression/cross-sell', {
				params: {
					sku_ids: skuIds.join(),
					type: buildTypeLabel(type),
				}
			})
		},
		
		canRemoveItemFromAutoDelivery(clinId) {
			let recipientId = null
			// First get the recipient ID
			for(var i = 0; i < this.cart?.recipients?.length; i++ ) {
				for(var j = 0; j < this.cart.recipients[i].items.length; j++ ) {
					if(this.cart.recipients[i].items[j].clin_id == clinId) {
						recipientId = this.cart.recipients[i].crec_id
					}
				}
			}

			if(!recipientId) { return false }

			let recipient = this.getRecipById(recipientId)

			if(!recipient) { return false }

			// First loop 
			for(var j = 0; j < recipient.items.length; j++ ) {
				// Are there any other A/D qualifying items on this shipment
				if(recipient.items[j].clin_id == clinId) {
					// If this item is not a qualifier - it can be removed
					if(!recipient.items[j].auto_delivery_eligible) {
						return true
					}					
				} else {
					let detail = recipient.items[j].detail
					let interval = 0
					if (detail.hasOwnProperty('perpetual_subscription') && detail.perpetual_subscription.hasOwnProperty('interval_months')) {
						interval = detail.perpetual_subscription.interval_months
					}
					// This is not the item in question - Is it a qualifier AND selected for auto-delivery??
					if(recipient.items[j].auto_delivery_eligible && interval != 0) {
						return true
					}
				}
			}

			// This item is a qualifier...but we did not find another qualifier IN THE AUTO DELIVERY - can't remove
			return false
		}
	},

	getters: {
		getMiniCartCount(state) {
			return state.cartCount
		},
		getMiniCartItems(state) {
			return state.miniCartItems
		},
		getBillingSameAsShipping(state) {
			return state.billingSameAsShipping
		},
		doesCartAddonQualify(state ) {
			return state.cartAddonQualify
		},
		fullCart(state) {
			return state.cart
		},
		getRecipients(state) {
			return state.cart.recipients
		},
		getSavedAddresses(state) {
			return state.cart.saved_addresses
		},
		getRecipById(state) {
			return (crec_id) => state.cart.recipients.find((recip) => recip.crec_id === crec_id)
		},
		getRecipByGuid(state) {
			return(guid) => state.cart.recipients.find((recip) => recip.recipient_guid === guid)
		},
		recipCount(state) {
			return state.cart?.recipients?.length
		},
		specificDayUpgrade(state) {
			return state.cart.specific_day_upgrade ?? 0
		},
		groupOvernightUpgradeCost(state) {
			return state.cart.group_overnight_upgrade_cost ?? 0
		},
		getRecentlyViewedItem(state) {
			return state.recentlyViewedItems
		},
		getSMSOptInFlag(state) {
			return state.smsOptInFlag
		},
		getFieldErrors(state) {
			return state.fieldErrors
		},
		getPaypalStashedBillingAddress(state) {
			return state.paypalStashedBillingAddress
		},
		getQASComplete(state) {
			return state.QASComplete
		},
		getAutoDelivery(state) {
			return state.hasAutoDelivery
		},
		getAutoDeliveryFrequency(state) {
			return function(crecId) {
				for(var i = 0; i < state.cart?.recipients?.length; i++ ) {
					if(state.cart.recipients[i].crec_id == crecId){
						for(var j = 0; j < state.cart.recipients[i].items.length; j++ ) {
							let detail = state.cart.recipients[i].items[j].detail
							if (
									   detail.hasOwnProperty('perpetual_subscription')
									&& detail.perpetual_subscription.hasOwnProperty('interval_months')
									&& detail.perpetual_subscription.interval_months != "0"
							) {
								return detail.perpetual_subscription.interval_months
							}
						}
					}
				}
				return '0'
			}
		},
		getOutOfStockItems(state) {
			return state.outOfStockItems
		},
		getCartStock(state) {
			return state.cartStock
		},
		getABData(state) {
			return state.cart.ab_tests
		},
		getCartRecalcDetail(state) {
			return state.cart.recalc_detail
		},
		getCartCount(state) {
			let count = 0

			state.cart?.recipients?.map(({ items = [] }) => {
				// console.log('cart count items?:', items)
				count += items.length
			})

			return count
		},
		getMyselfAddress(state) {
			return state.cart?.saved_addresses?.find((address) => parseInt(address.is_myself) === 1)
		},
		getAddressesById(state) {
			if (!state.cart?.saved_addresses) return {}

			return state.cart?.saved_addresses?.reduce((byId, address) => {
				return {
					[address.addr_id]: address,
					...byId
				}
			}, {})
		},
		getSkuIds(state) {
			let recipients = state.cart.recipients
			let skuArray = []
			if (state.cart?.recipients?.length > 0) {
				recipients.map((recipient) => {
					recipient.items.map((item) => {
						skuArray.push(item.sku_id)
					})
				})
			}
			return skuArray
		},
		getCartTotal(state) {
			return state.cart?.summary_section_totals?.cart_net_total
		},
		shipmentHasAutoDelivery(state) {
			return function(crecId) {
				let recip = this.getRecipById(crecId)
				for(var j = 0; j < recip.items.length; j++ ) {
					let detail = recip.items[j].detail
					if (detail.hasOwnProperty('perpetual_subscription') && detail.perpetual_subscription.hasOwnProperty('interval_months')) {
						let interval = detail.perpetual_subscription.interval_months
						if(interval != '0') {
							return true;
						}
					}
				}

				return false;
			}
		},
		shipmentEligibleForAutoDelivery(state) {
			return function(crecId) {
				let recip = {}
				if(crecId) {
					recip = this.getRecipById(crecId)
				} else {
					recip = state.cart.recipients[0]
				}
				for(var j = 0; j < recip.items.length; j++ ) {
					if(recip.items[j].auto_delivery_eligible) {
						return true;
					}
				}

				return false;
			}
		},
		isAutoDelivery(state) {
			let hasAutoDelivery = false

			for(var i = 0; i < state.cart?.recipients?.length; i++ ) {
				for(var j = 0; j < state.cart.recipients[i].items.length; j++ ) {
					let detail = state.cart.recipients[i].items[j].detail
					if (detail.hasOwnProperty('perpetual_subscription') && detail.perpetual_subscription.hasOwnProperty('interval_months')) {
						let interval = detail.perpetual_subscription.interval_months
						if(interval != '0') {
							hasAutoDelivery = true
						}
					}
				}
			}

			return hasAutoDelivery
		},
		clubItemRecipients() {
			return this.cart?.recipients?.filter((recipient) => 
				recipient.items.find((item) => item.detail.club_orders))
		},
		getIsMobile(state) {
			return state.cart.is_mobile
		},
		shipCalendarGroups() {
			return this.cart?.ship_calendar_groups || []
		},
		getCalendarGroupByDate() {
			return (date) => {
				if (!this.shipCalendarGroups.length) return null
	
				const $date = dayjs(date)
	
				return this.shipCalendarGroups.find((group) => {
					const start = dayjs(group.start_date)
					const end = dayjs(group.end_date)
	
					return $date.isAfter(start) && $date.isBefore(end)
				})
			}
		}
	},
	persist: true
})